export const bannerThemes = {
    black: {
        background: 'var(--color-bg-banner-black)',
        color: 'var(--color-text-banner-black)',
    },
    white: {
        background: 'var(--color-bg-banner-white)',
        color: 'var(--color-text-banner-white)',
    },
};
