import fm from 'format-message';
import PropTypes from 'prop-types';
import useAccountQuery from 'queries/useAccountQuery';
import BaseLink from '@activebrands/core-web/components/Link';
import overlay, { useOverlaySubscriber } from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { useTypedSelector } from '@activebrands/core-web/state/store';
import AccountIcon from 'components/icons/AccountIcon';

const Link = styled(BaseLink, {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: '12px',
});

const AuthLink = ({ $style, isNoFollow, linkText, path, Icon = AccountIcon }) => {
    const [css] = useStyletron();
    const isAuthenticated = useTypedSelector(s => s.auth.isAuthenticated);
    const isUnauthenticatedOpen = useOverlaySubscriber('unauthenticated');
    const { accountPage } = useAccountQuery();

    const generateLinkProps = () => {
        // If the user is not authenticated, we open the unauthenticated
        // overlay.
        if (!isAuthenticated) {
            return {
                role: 'button',
                tabIndex: '0',
                onClick: () => {
                    overlay.closeAll();
                    if (!isUnauthenticatedOpen) {
                        overlay.open('unauthenticated', { redirectUri: path || accountPage });
                    }
                },
            };
        }

        // If the user is authenticated, we navigate to a given path or the
        // account page by default.
        const linkAttributes = {
            to: path || accountPage,
            title: fm('My pages'),
        };

        // If isNoFollow flag is true, add rel="nofollow" to the link attributes
        if (isNoFollow) {
            linkAttributes.rel = 'nofollow';
        }

        return linkAttributes;
    };

    const linkProps = generateLinkProps();

    return (
        <Link isVolatile $style={$style} {...linkProps}>
            <Icon
                $style={{
                    display: 'block',
                    width: ['24px', null, null, null, null, '16px'],
                    height: ['24px', null, null, null, null, '16px'],
                    marginRight: linkText ? '8px' : '0',
                }}
            />
            {linkText && (
                <span
                    className={css({
                        lineHeight: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    })}
                >
                    {linkText}
                </span>
            )}
        </Link>
    );
};

AuthLink.propTypes = {
    $style: PropTypes.object,
    Icon: PropTypes.elementType,
    isNoFollow: PropTypes.bool,
    linkText: PropTypes.string,
    path: PropTypes.string,
};

export default AuthLink;
