/**
 * Determines whether the current date is within the specified team ordering period.
 *
 * @param {string} orderingStartDateStr - The start date of the ordering period.
 * @param {string} orderingEndDateStr - The end date of the ordering period .
 * @returns {boolean} - True if the current date is within the ordering period, false otherwise.
 */

const getIsWithinTeamOrderingPeriod = (orderingStartDateStr: string, orderingEndDateStr: string) => {
    const currentDate = new Date();
    const orderingStartDate = new Date(orderingStartDateStr);
    const orderingEndDate = new Date(orderingEndDateStr);

    // Add one day to the ordering end date to include the entire day
    const orderingEndOfDayDate = new Date(orderingEndDate);
    orderingEndOfDayDate.setDate(orderingEndDate.getDate() + 1);

    return currentDate >= orderingStartDate && currentDate <= orderingEndOfDayDate;
};

export default getIsWithinTeamOrderingPeriod;
