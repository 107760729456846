import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const ArrowIcon = ({ $style = {}, color = 'currentColor', size = '24px', viewBox = '0 0 12 12', ...rest }) => {
    const style = { ...$style };

    return (
        <SVG $style={style} width={size} height="auto" fill="none" viewBox={viewBox} {...rest}>
            <path stroke={color} d="M4.5 11.5 11 6 4.5.5M1 6h9.5" />
        </SVG>
    );
};

ArrowIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    viewBox: PropTypes.string,
};

export default ArrowIcon;
