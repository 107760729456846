import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const MinusIcon = ({ $style = {}, color = 'currentColor', viewBox = '0 0 24 24', size = '24px', ...rest }) => {
    const style = { ...$style };
    return (
        <SVG $style={style} width={size} height="auto" viewBox={viewBox} fill="none" {...rest}>
            <path stroke={color} d="M24 12H0m24 0H0" />
        </SVG>
    );
};

MinusIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    viewBox: PropTypes.string,
};

export default MinusIcon;
