import { ReactNode, useEffect, useRef } from 'react';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';

const Messages = styled('ul', {
    height: '100%',
});

interface FlipClockProps {
    $style: any;
    animationDelay: number;
    animationTime: number;
    children: ReactNode[];
    spin: boolean;
}

const FlipClock = ({
    $style = {},
    animationDelay = 3000,
    animationTime = 1000,
    children,
    spin = true,
}: FlipClockProps) => {
    const [css] = useStyletron();
    const messagesRef = useRef<HTMLDivElement>(null);

    if (!children || messagesRef == null) {
        return null;
    }

    useEffect(() => {
        let index = 0;
        let loopTimeout;
        let resetTimeOut;

        const clearLoop = () => {
            clearTimeout(loopTimeout);
            clearTimeout(resetTimeOut);
        };

        const triggerLoop = () => {
            // Add transition and transform to next message
            if (messagesRef.current) {
                messagesRef.current.style.transform = `translateY(-${100 * index}%)`;
                messagesRef.current.style.transition = `transform ${animationTime}ms ease`;
                index++;
            }

            // Reset styling and index on the last message
            if (index > children?.length) {
                // Reset to 1 instead of 0 to avoid the first transition due to cloning
                index = 1;

                // Wait for animationTime before reset
                resetTimeOut = setTimeout(() => {
                    if (messagesRef.current) {
                        messagesRef.current.style.transform = `translateY(0%)`;
                        messagesRef.current.style.transition = `transform 0ms ease`;
                    }
                }, animationTime);
            }

            loopTimeout = setTimeout(triggerLoop, animationTime + animationDelay);
        };

        const initLoop = () => {
            if (messagesRef.current) {
                messagesRef.current.append(messagesRef.current.children[0].cloneNode(true));
                if (spin) {
                    triggerLoop();
                }
            }
        };

        if (children.length > 1) {
            initLoop();
        }

        return () => {
            clearLoop();
        };
    }, [messagesRef]);

    return (
        <div
            className={css({
                display: 'flex',
                justifyContent: 'center',
                overflow: 'hidden',
                height: '24px',
                ...$style,
            })}
        >
            <Messages ref={messagesRef}>{children}</Messages>
        </div>
    );
};

export default FlipClock;
