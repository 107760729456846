import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import RichText, { RichTextPropType } from '@activebrands/core-web/libs/storyblok/RichText';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import Heading from 'components/text/Heading';

// MaxRows 99 beacuse we don't want to limit the number of rows in the feature card but keep the option to change
const FeatureCard = ({ $style = {}, background, backgroundOverlay, heading, headingType, maxRows = 99, richText }) => {
    const [css] = useStyletron();

    return (
        <div
            className={css({
                border: '1px solid var(--color-border-feature-card)',
                position: 'relative',
                display: 'flex',
                flexDirection: ['column', null, null, null, null, 'row'],
                ...$style,
            })}
        >
            {background?.length > 0 && (
                <div
                    className={css({
                        aspectRatio: 'var(--ratio-square)',
                        marginBottom: ['12px', null, null, null, null, '0'],
                        position: 'relative',
                        width: ['100%', null, null, null, null, '50%'],
                    })}
                >
                    <BackgroundDynamic
                        $style={{ zIndex: 1 }}
                        background={background}
                        backgroundOverlay={backgroundOverlay}
                        loading="lazy"
                    />
                </div>
            )}
            {(heading || richText) && (
                <div
                    className={css({
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: ['flex-start', null, null, null, null, 'space-between'],
                        padding: ['16px', null, null, null, null, '24px'],
                        width: ['100%', null, null, null, null, '50%'],
                    })}
                >
                    {heading && (
                        <Heading as={headingType} fontKeys="Primary/24_120_-1">
                            {heading}
                        </Heading>
                    )}
                    {richText && (
                        <div
                            className={css({
                                display: '-webkit-box',
                                height: 'auto',
                                overflow: 'hidden',
                                marginTop: '32px',
                                '-webkit-box-orient': 'vertical',
                                '-webkit-line-clamp': maxRows,
                                ...textStyles['Primary/14_140_-2'],
                            })}
                        >
                            <RichText data={richText} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

FeatureCard.propTypes = {
    $style: PropTypes.object,
    background: PropTypes.arrayOf(PropTypes.object),
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    heading: PropTypes.string,
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    maxRows: PropTypes.number,
    richText: RichTextPropType,
};

export default FeatureCard;
