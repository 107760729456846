import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import media from '@activebrands/core-web/config/media';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';
import getTextColorStyles from '@activebrands/core-web/utils/style-functions/get-text-color-styles';
import ButtonsList from 'components/buttons/ButtonsList';
import Heading from 'components/text/Heading';
import Tag from 'components/text/Tag';

const Wrapper = styled('div', {
    position: 'relative',
    height: '100%',
});

const ContentWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
});

const Content = styled('div', {
    position: 'relative',
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'var(--color-bg-discover-module)',
    paddingTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    [media.min['mobile.lg']]: {
        gap: '24px',
    },

    [media.min['desktop.sm']]: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        display: 'grid',
        padding: '24px',
        gridTemplateColumns: 'repeat(12, 1fr)',
    },
});

const Categories = styled('div', {
    gridRow: 2,
    gridColumn: '1 / span 12',
});

const StyledTag = styled('tag', {
    display: 'block',
    marginBottom: '12px',
    padding: '0 16px',
    ...textStyles['Primary/14_100_-3'],

    [media.min['desktop.sm']]: {
        padding: '0',
    },
});

const Discover = ({
    background,
    backgroundLoading = 'eager',
    backgroundOverlay,
    buttons = [],
    categoriesTag,
    categoryButtons = [],
    heading,
    headingStyle = 'md',
    headingType,
    id,
    tag,
    text,
    textColor = 'black',
}) => {
    const [css] = useStyletron();

    const textColorStyle = getTextColorStyles(textColor);

    const headingFontKeys = {
        sm: ['Primary/32_100_-2', null, null, null, null, 'Primary/48_100_-3'],
        md: ['Primary/40_110_-2', null, null, null, null, 'Primary/80_90_-4'],
    };

    return (
        <Wrapper id={id}>
            <ContentWrapper $style={textColorStyle}>
                <div
                    className={css({
                        width: '100%',
                        height: '100%',
                        zIndex: ['1', null, null, null, null, 'unset'],
                        position: ['relative', null, null, null, null, 'absolute'],
                        inset: ['unset', null, null, null, null, '0'],
                    })}
                >
                    <BackgroundDynamic
                        background={background}
                        backgroundOverlay={backgroundOverlay}
                        loading={backgroundLoading}
                    />
                </div>
                <Content>
                    <div
                        className={css({
                            gridColumn: '1 / span 5',
                            width: '100%',
                            padding: ['0 16px', null, null, null, null, '0'],
                        })}
                    >
                        {tag && (
                            <Tag $style={{ padding: '0 6px', marginBottom: ['16px', null, '24px', null, '8px'] }}>
                                {tag}
                            </Tag>
                        )}
                        {heading && (
                            <Heading as={headingType} fontKeys={headingFontKeys[headingStyle]}>
                                {heading}
                            </Heading>
                        )}
                    </div>
                    {text && (
                        <div
                            className={css({
                                gridColumn: '7 / span 3',
                                padding: ['0 16px', null, null, null, null, '0'],
                                ...responsiveTextStyles([
                                    'Primary/16_140_-05',
                                    null,
                                    null,
                                    null,
                                    null,
                                    'Primary/18_120_-05',
                                ]),
                            })}
                        >
                            <RichText data={text} />
                        </div>
                    )}
                    <div
                        className={css({
                            gridColumn: '11 / span 2',
                            padding: ['0 16px', null, null, null, null, '0'],
                        })}
                    >
                        <ButtonsList
                            $style={{
                                display: 'inline-flex',
                                justifyContent: ['flex-start', null, null, null, null, 'flex-end'],
                                flexDirection: ['row', null, null, null, null, 'column'],
                                width: '100%',
                            }}
                            buttons={buttons}
                            fontKeys="Primary/18_100_-05"
                        />
                    </div>
                    {!!categoryButtons?.length && (
                        <Categories>
                            <div
                                className={css({
                                    width: ['calc(100% - 32px)', null, null, null, null, '100%'],
                                    height: '1px',
                                    backgroundColor: 'var(--color-border-discover-module)',
                                    margin: '0 auto 24px',
                                })}
                            />
                            {categoriesTag && <StyledTag>{categoriesTag}</StyledTag>}
                            <ButtonsList
                                $style={{
                                    flexDirection: 'row',
                                    gap: 'unset',
                                    padding: ['0 16px', null, null, null, null, '0'],
                                    width: ['auto', null, null, null, '100%'],
                                    overflowX: ['auto', null, null, null, 'hidden'],

                                    '-webkit-overflow-scrolling': 'touch',
                                    '::-webkit-scrollbar': {
                                        display: 'none',
                                    },

                                    // Adds the same space as gap in front and after
                                    '::before': {
                                        display: ['block', null, null, null, null, 'none'],
                                        content: '""',
                                        width: '0px',
                                    },
                                    '::after': {
                                        display: ['block', null, null, null, null, 'none'],
                                        content: '""',
                                        width: '0px',
                                    },
                                }}
                                buttonStyling={{ marginRight: '8px' }}
                                buttons={categoryButtons}
                                fontKeys="Primary/16_100_-05"
                            />
                        </Categories>
                    )}
                </Content>
            </ContentWrapper>
        </Wrapper>
    );
};

Discover.propTypes = {
    background: PropTypes.arrayOf(
        PropTypes.object
        // @todo: This gives "Failed prop type: checker is not a function".
        // PropTypes.oneOfType([contentBackgroundColorProp, contentImageProp, contentVideoProp])
    ),
    backgroundLoading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    categoriesTag: PropTypes.string,
    categoryButtons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    heading: PropTypes.string,
    headingStyle: PropTypes.oneOf(['sm', 'md']),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    tag: PropTypes.string,
    text: PropTypes.object,
    textColor: PropTypes.string,
};

export default Discover;
