import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ArrowPagination from 'components/slider/ArrowPagination';
import Heading from 'components/text/Heading';
import StateButton from './buttons/StateButton';
import Paragraph from './text/Paragraph';

const Wrapper = styled('div', ({ $isSlider, $type }) => ({
    width: '100%',
    height: '100%',
    marginBottom: '24px',
    padding: '0 var(--margin-content)',

    [media.min['mobile.lg']]:
        $type === 'product'
            ? $isSlider
                ? {
                      height: 'auto',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                  }
                : {
                      display: 'flex',
                      justifyContent: 'space-between',
                  }
            : {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
              },

    [media.min['desktop.sm']]: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: '24px',
    },
}));

const ButtonWrapper = styled('div', ({ $type }) => ({
    display: 'none',
    [media.min['mobile.lg']]:
        $type === 'product'
            ? {
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '12px',
                  justifyContent: 'flex-end',
                  height: '100%',
              }
            : {},
    [media.min['desktop.sm']]:
        $type === 'article' || $type === 'feature'
            ? {
                  display: 'flex',
                  gap: '12px',
                  flexDirection: 'row',
              }
            : {},
}));

const PromotionalHeader = ({
    $paginationStyle,
    $style,
    button,
    heading,
    headingFontKeys,
    headingType,
    tagFontKeys = [],
    paragraphFontKeys,
    slider,
    tag,
    text,
    type,
}) => {
    const [css] = useStyletron();

    return (
        <Wrapper $style={$style} $type={type} $isSlider={!!slider}>
            <div className={css({ width: ['100%', null, '50%'] })}>
                {tag && (
                    <Paragraph
                        as="span"
                        fontKeys={tagFontKeys}
                        $style={{ display: 'block', marginBottom: ['24px', null, null, '12px'] }}
                    >
                        {tag}
                    </Paragraph>
                )}
                {heading && (
                    <Heading
                        $style={{ marginBottom: ['8px', null, null, '12px'] }}
                        as={headingType}
                        fontKeys={headingFontKeys}
                    >
                        {heading}
                    </Heading>
                )}
                <div className={css({ ...textStyles[paragraphFontKeys] })}>
                    <RichText data={text} />
                </div>
            </div>
            <div className={css({ display: 'flex', gap: '12px', flexDirection: 'column', justifyContent: 'flex-end' })}>
                <ButtonWrapper $type={type}>
                    {button?.label && button?.url && (
                        <StateButton
                            iconSize="16px"
                            fontKeys="Primary/16_100_-05"
                            $style={{
                                marginLeft: slider ? '0' : ['auto', null, null, null, null, '0'],
                                width: slider ? '100%' : [null, null, null, null, null, '100%'],
                                marginTop: 'auto',
                                height: '40px',
                            }}
                            as={Link}
                            rel={button.rel}
                            theme={button.theme}
                            to={button.url}
                        >
                            {button.label}
                        </StateButton>
                    )}
                    <ArrowPagination
                        $style={{
                            marginTop: 'auto',
                            width: '48px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 'unset',
                            ...$paginationStyle,
                        }}
                        slider={slider}
                    />
                </ButtonWrapper>
            </div>
        </Wrapper>
    );
};

PromotionalHeader.propTypes = {
    $paginationStyle: PropTypes.object,
    $style: PropTypes.object,
    button: PropTypes.object,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    paragraphFontKeys: PropTypes.object,
    slider: PropTypes.object,
    tag: PropTypes.string,
    tagFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    text: PropTypes.string,
    type: PropTypes.string,
};

export default PromotionalHeader;
