import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Link from '@activebrands/core-web/components/Link';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import LeaningImage from 'components/LeaningImage';

const Wrapper = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '4px',
    marginLeft: '4px', // to compensate in mobile view
    ...textStyles['Primary/12_100_-1_500'],
});

const ColorSwatches = ({
    $selectedStyleAfter = {},
    $selectedStyleBefore = {},
    $style = {},
    currentVariationId = '',
    imageStyles = { width: '10px', height: '16px' },
    relatedVariations = [],
    maxColors = 4,
}) => {
    const [css] = useStyletron();

    return (
        <Wrapper $style={$style}>
            {relatedVariations.slice(0, maxColors).map(({ colorHex, colorImageUrl, colorName, id, name, uri }) => (
                <Link replace key={id} title={colorName} to={uri}>
                    <LeaningImage
                        $style={{ ...imageStyles, backgroundColor: `#${colorHex}` }}
                        $selectedStyleBefore={$selectedStyleBefore}
                        $selectedStyleAfter={$selectedStyleAfter}
                        isSelected={id === currentVariationId}
                        name={name}
                        src={
                            colorImageUrl ? { width: imageStyles.width, url: colorImageUrl, format: 'jpg' } : undefined
                        }
                    />
                </Link>
            ))}

            {relatedVariations.length > maxColors && (
                <span className={css({ marginLeft: '4px', display: 'block' })}>
                    +{relatedVariations.length - maxColors}
                </span>
            )}
        </Wrapper>
    );
};

ColorSwatches.propTypes = {
    $selectedStyleAfter: PropTypes.object,
    $selectedStyleBefore: PropTypes.object,
    $style: PropTypes.object,
    currentVariationId: PropTypes.string,
    imageStyles: PropTypes.shape({
        width: PropTypes.string.isRequired,
        height: PropTypes.string,
    }),
    maxColors: PropTypes.number,
    relatedVariations: PropTypes.array,
};

export default ColorSwatches;
