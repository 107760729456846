import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import useTeamStoreUrlQuery from 'queries/useTeamStoreUrlQuery';
import Link from '@activebrands/core-web/components/Link';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import AuthLink from 'components/Header/AuthLink';
import ThemeButton from 'components/buttons/ThemeButton';
import TeamStoreIcon from 'components/icons/TeamStoreIcon';

const ENABLE_TEAMSTORE = process.env.REACT_APP_ENABLE_TEAMSTORE;

const List = styled('ul', {
    display: 'flex',
    flexDirection: 'column',
});

const SecondaryMenuItem = styled('li', {
    display: 'flex',
});

const SecondaryLevelOneDesktop = ({ items = [], menuValue = '', setMenuValue }) => {
    const [css] = useStyletron();
    const { teamStoreCategoryPage: teamStoreCategoryPagePath } = useTeamStoreUrlQuery();

    if (!items?.length) {
        return null;
    }

    return (
        <List>
            {items?.map(item => {
                return (
                    <SecondaryMenuItem $style={textStyles['Primary/18_100_-05']} key={item.label}>
                        <ThemeButton
                            isVolatile
                            theme="link"
                            $style={{
                                padding: '6px 12px',
                            }}
                            fontKeys="Primary/20_120_-1"
                            as={Link}
                            rel={item.follow === 'no_follow' ? 'nofollow' : undefined}
                            to={item?.link?.url}
                            onClick={() => {
                                overlay.close('navigation');
                                setMenuValue({ current: null, prev: menuValue.current });
                            }}
                        >
                            {item.label}
                        </ThemeButton>
                    </SecondaryMenuItem>
                );
            })}
            <SecondaryMenuItem $style={textStyles['Primary/18_100_-05']} key="Team Store">
                <div
                    className={css({
                        ':hover': { color: 'var(--color-icon-button-default-active)' },
                    })}
                    onClick={() => overlay.close('validate-team-account')}
                >
                    {ENABLE_TEAMSTORE === 'true' && (
                        <AuthLink
                            isNoFollow
                            $style={{
                                padding: '6px 12px',
                            }}
                            linkText={fm('Team Store')}
                            Icon={TeamStoreIcon}
                            path={teamStoreCategoryPagePath}
                        />
                    )}
                </div>
            </SecondaryMenuItem>
        </List>
    );
};

SecondaryLevelOneDesktop.propTypes = {
    items: PropTypes.array.isRequired,
    menuValue: PropTypes.exact({
        current: PropTypes.string,
        prev: PropTypes.string,
    }).isRequired,
    setMenuValue: PropTypes.func.isRequired,
};

export default SecondaryLevelOneDesktop;
