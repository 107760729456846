import { useState } from 'react';
import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import Image from '@activebrands/core-web/components/Image';
import AnimatedOverlay from '@activebrands/core-web/components/overlays/AnimatedOverlay';
import media from '@activebrands/core-web/config/media';
import { useDidUpdate } from '@activebrands/core-web/hooks/lifecycle';
import { useOverlaySubscriber } from '@activebrands/core-web/libs/overlay';
import useScrollLock from '@activebrands/core-web/libs/scroll';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import loadable from '@loadable/component';
import PrimaryMenu from 'components/Header/Navigation/PrimaryMenu';
import ExtraLinks from 'components/Header/Navigation/PrimaryMenu/ExtraLinks';
import FeaturedItems from 'components/Header/Navigation/PrimaryMenu/FeaturedItems';
import PrimaryMenuBackdrop from 'components/Header/Navigation/PrimaryMenu/PrimaryMenuBackdrop';
import SecondaryMenu from 'components/Header/Navigation/SecondaryMenu';
import SiteSelector from 'components/Header/Navigation/SiteSelector';
import PrimaryMenuHeading from 'components/Header/PrimaryMenuHeading';
import ThemeButton from 'components/buttons/ThemeButton';
import CloseIcon from 'components/icons/CloseIcon';
import PlusIcon from 'components/icons/PlusIcon';
import Paragraph from 'components/text/Paragraph';

const SiteSelectorOverlay = loadable(() => import('components/overlays/SiteSelectorOverlay'));

const Wrapper = styled('div', ({ $isOpen }) => ({
    position: 'fixed',
    left: 0,
    bottom: 'var(--height-header)',
    height: `calc(var(--vh) - var(--height-header))`,
    transform: $isOpen ? 'translate3d(0%, 0, 0)' : 'translate3d(-100%, 0, 0)',
    width: 'calc(100vw - 8px)',
    zIndex: 'var(--zindex-header)',
    transitionDuration: 'var(--duration-fast)',
    transitionProperty: 'transform',
    transitionTimingFunction: 'var(--ease)',

    [media.min['desktop.sm']]: {
        height: `calc(var(--vh) - var(--height-header) - 12px )`,
        top: 'calc(var(--height-header) + 4px)',
        bottom: '4px',
    },
}));

const SiteSelectorWrapper = styled('div', {
    position: 'absolute',
    width: '100%',
    bottom: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid var(--color-border-navigation)',
    backgroundColor: 'var(--color-bg-navigation)',
    zIndex: 1,

    [media.min['desktop.sm']]: {
        bottom: '0',
    },
});

const InnerWrapper = styled('div', {
    height: '100%',
    position: 'relative',
    maxWidth: 'var(--width-overlays)',
    zIndex: 'calc(var(--zindex-header) + 2)',
});

const Section = styled('section', ({ $isOpen }) => ({
    transitionDuration: 'var(--duration-fast)',
    transitionProperty: 'transform',
    transitionTimingFunction: 'var(--ease)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'absolute',
    transform: $isOpen ? 'translate3d(0%, 0, 0)' : 'translate3d(-100%, 0, 0)',
    width: '100%',
    zIndex: 'calc(var(--zindex-header) + 3)',
    margin: '4px',

    '::after': {
        position: 'absolute',
        right: 0,
        bottom: 0,
        display: 'block',
        content: '',
        width: '100%',
        height: '100%',
        zIndex: '-1',
    },

    [media.min['desktop.sm']]: {
        top: 0,
        width: '100%',
    },
}));

const Nav = styled('nav', {
    backgroundColor: 'var(--color-bg-header)',
    color: 'var(--color-text-navigation)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: `calc(var(--vh) - var(--height-header) - 64px )`,
    overflowY: 'auto',
    width: '100%',
    zIndex: 'calc(var(--zindex-header) + 4)',
    cursor: 'default',
    paddingTop: '24px',

    [media.min['desktop.sm']]: {
        paddingTop: 'unset',
    },
});

const Navigation = ({
    alternateHrefs = [],
    menuValue = '',
    pageData = {},
    resetNavigation = () => null,
    setMenuValue = () => null,
}) => {
    const [css] = useStyletron();
    const {
        siteSelectorHeading,
        siteSelectorHeadingType,
        siteSelectorParagraph,
        siteSelectorRedirectLabel,
        siteSelectorLinks,
    } = useSiteSelectorQuery();

    const aliasId = useSelector(state => state.application.aliasId);
    const currentSite = siteSelectorLinks.find(site => site.id == aliasId);
    const { lockScroll, unlockScroll } = useScrollLock();
    const isOpen = useOverlaySubscriber('navigation');

    const [isSiteSelectorOpen, setIsSiteSelectorOpen] = useState(false);
    const { featuredLabel = '', featuredItems = [], primaryMenu = [], secondaryMenu = [], extraLinks = [] } = pageData;

    useDidUpdate(() => {
        isOpen ? lockScroll() : unlockScroll();

        if (!isOpen) {
            resetNavigation();

            if (isSiteSelectorOpen) {
                setIsSiteSelectorOpen(!isSiteSelectorOpen);
            }
        }
    }, [isOpen]);

    const toggleSiteSelector = () => {
        setIsSiteSelectorOpen(!isSiteSelectorOpen);
        setMenuValue({ current: 0, prev: menuValue.current });
    };

    const isSiteSelectorOrSubMenuOpen = isSiteSelectorOpen || !!menuValue?.current;

    return (
        <Wrapper $isOpen={isOpen}>
            <PrimaryMenuBackdrop
                $isOpen={isOpen}
                $zIndex="calc(var(--zindex-header) + 2)"
                onClick={() => resetNavigation()}
            />
            <InnerWrapper>
                <Section $isOpen={isOpen}>
                    <PrimaryMenuHeading
                        isSiteSelectorOpen={isSiteSelectorOpen}
                        toggleSiteSelector={toggleSiteSelector}
                        resetNavigation={resetNavigation}
                        text={fm('Menu')}
                        $style={{
                            display: ['flex', null, null, null, 'none'],
                            zIndex: 2,
                            borderBottom: '1px solid var(--color-border-navigation)',
                        }}
                    />
                    <Nav $style={{ paddingBottom: isSiteSelectorOrSubMenuOpen ? '0' : '80px' }} id="navigation">
                        <div
                            className={css({
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                            })}
                        >
                            <>
                                <div className={css({ paddingBottom: '72px', zIndex: '1' })}>
                                    <FeaturedItems
                                        $style={{ marginBottom: '24px' }}
                                        items={featuredItems}
                                        label={featuredLabel}
                                    />
                                    <PrimaryMenu
                                        items={primaryMenu}
                                        menuValue={menuValue}
                                        resetNavigation={resetNavigation}
                                        setMenuValue={setMenuValue}
                                    />
                                    <SecondaryMenu
                                        items={secondaryMenu}
                                        menuValue={menuValue}
                                        setMenuValue={setMenuValue}
                                    />
                                    <ExtraLinks items={extraLinks} />
                                </div>
                            </>

                            <AnimatedOverlay
                                lockScroll
                                $style={{ overflow: 'hidden', height: '100%', width: '100%', top: '0' }}
                                alternateHrefs={alternateHrefs}
                                animation="slideLTR"
                                component={SiteSelectorOverlay}
                                id="site-selector-nav"
                            />
                            <SiteSelector
                                alternateHrefs={alternateHrefs}
                                isSiteSelectorOpen={isSiteSelectorOpen}
                                setIsSiteSelectorOpen={setIsSiteSelectorOpen}
                                siteSelectorHeading={siteSelectorHeading}
                                siteSelectorHeadingType={siteSelectorHeadingType}
                                siteSelectorParagraph={siteSelectorParagraph}
                                siteSelectorRedirectLabel={siteSelectorRedirectLabel}
                            />
                            <SiteSelectorWrapper $style={{ display: isSiteSelectorOrSubMenuOpen ? 'none' : 'block' }}>
                                <ThemeButton
                                    active={isSiteSelectorOpen}
                                    $style={{
                                        width: '100%',
                                        padding: '16px 20px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        borderStyle: 'none',
                                    }}
                                    theme="none"
                                    onClick={() => toggleSiteSelector()}
                                >
                                    <div className={css({ display: 'flex' })}>
                                        <div className={css({ marginRight: '12px', width: '24px', height: '16px' })}>
                                            <Image
                                                sizes={['24px']}
                                                src={{ url: currentSite?.icon, width: [24, 48, 72] }}
                                            />
                                        </div>
                                        <Paragraph fontKeys="Primary/14_100_-3">{currentSite?.label}</Paragraph>
                                    </div>
                                    {!!isSiteSelectorOpen ? <CloseIcon width="12px" /> : <PlusIcon width="12px" />}
                                </ThemeButton>
                            </SiteSelectorWrapper>
                        </div>
                    </Nav>
                </Section>
            </InnerWrapper>
        </Wrapper>
    );
};

Navigation.propTypes = {
    alternateHrefs: PropTypes.array,
    menuValue: PropTypes.object,
    pageData: PropTypes.object,
    resetNavigation: PropTypes.func,
    setMenuValue: PropTypes.func,
};

export default Navigation;
