import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import Slider from '@activebrands/core-web/components/Slider';
import media from '@activebrands/core-web/config/media';
import createSlider from '@activebrands/core-web/libs/slider/create-slider';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import PromotionalHeader from 'components/PromotionalHeader';
import StateButton from 'components/buttons/StateButton';

const Wrapper = styled('div', ({ $type }) => ({
    [media.min['mobile.lg']]:
        $type === 'product'
            ? {
                  display: 'flex',
                  flexDirection: 'column',
              }
            : {},
    [media.min['desktop.sm']]:
        $type === 'article' || $type === 'feature'
            ? {
                  display: 'flex',
                  flexDirection: 'column',
              }
            : {},
}));

const PromotionalHeaderWrapper = styled('div', ({ $type }) => ({
    [media.min['mobile.lg']]:
        $type === 'product'
            ? {
                  gridColumn: '1 / span 5',
              }
            : {},
    [media.min['desktop.sm']]: {
        gridColumn: '1 / span 3',
    },
}));

const SliderWrapper = styled('div', ({ $type }) => ({
    [media.min['mobile.lg']]:
        $type === 'product'
            ? {
                  gridColumn: '6 / span 7',
              }
            : {},
    [media.min['desktop.sm']]: {
        gridColumn: '4 / span 9',
    },
}));

const PromotionalSlider = ({
    $paginationStyle = {},
    $style = {},
    button = {},
    heading = '',
    headingFontKeys,
    headingType = '',
    tag,
    tagFontKeys = [],
    text,
    type,
    ...sliderProps
}) => {
    const slider = createSlider();
    const [css] = useStyletron();

    return (
        <Wrapper $style={$style} $type={type}>
            <PromotionalHeaderWrapper $type={type}>
                <PromotionalHeader
                    $paginationStyle={$paginationStyle}
                    $style={{ zIndex: 1 }}
                    button={button}
                    heading={heading}
                    headingFontKeys={headingFontKeys}
                    headingType={headingType}
                    slider={slider}
                    tag={tag}
                    tagFontKeys={tagFontKeys}
                    text={text}
                    type={type}
                />
                {button.label && button.url && (
                    <div className={css({ padding: '0 var(--margin-content)' })}>
                        <StateButton
                            $style={
                                type === 'product'
                                    ? {
                                          marginTop: '24px',
                                          width: ['50%', null, 'auto'],
                                          display: ['flex', null, 'none'],
                                      }
                                    : {
                                          margin: '24px 0',
                                          width: ['50%', null, null, null, null, 'auto'],
                                          display: ['flex', null, null, null, null, 'none'],
                                      }
                            }
                            as={Link}
                            rel={button.rel}
                            theme={button.theme}
                            to={button.url}
                        >
                            {button.label}
                        </StateButton>
                    </div>
                )}
            </PromotionalHeaderWrapper>
            <SliderWrapper $type={type}>
                <Slider $style={{ padding: '0 var(--margin-content)' }} slider={slider} type={type} {...sliderProps} />
            </SliderWrapper>
        </Wrapper>
    );
};

PromotionalSlider.propTypes = {
    $paginationStyle: PropTypes.object,
    $style: PropTypes.object,
    button: PropTypes.object,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.string,
    tag: PropTypes.string,
    tagFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    text: PropTypes.string,
    type: PropTypes.string,
};

export default PromotionalSlider;
