import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const AccountIcon = ({
    $style = {},
    color = 'currentColor',
    fill = 'none',
    height = 'auto',
    width = '16px',
    ...rest
}) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} fill={fill} viewBox="0 0 16 16" {...rest}>
            <circle cx="8" cy="4" r="3.5" stroke={color} />
            <path stroke={color} d="M4 9.5h8c1.933 0 3.5 1.567 3.5 3.5v2.5H.5V13c0-1.933 1.567-3.5 3.5-3.5Z" />
        </SVG>
    );
};

AccountIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    fill: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default AccountIcon;
