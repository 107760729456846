/**
 * The header can have three different themes before scrolling on the page. After scroll, it always switches to default.
 * The logic for which theme to be chosen can be found in src/components/Heeader/getHeaderTheme.
 * In this file there is the option to set more themes than Default. These will not change if the page theme changes.
 * All themes look like default in mobile and tablet.
 *
 * Themes:
 *  * Default
 *  * TransparentBlack
 *  * TransparentWhite
 *
 */

const headerThemes = {
    // Static theme - not affected by site theme
    'default': {
        '--color-bg-header': 'var(--color-base-white)',
        '--color-border-search-header': 'var(--color-base-brand-black)',
        '--color-text-header': 'var(--color-text)',
        '--color-text-search-header': 'var(--color-base-temp-blue-4)',
        '--color-top-logo-mobile': 'var(--color-base-temp-blue-7)',
        '--color-top-logo-site-selector': 'var(--color-base-white)',
    },
    'checkout': {
        '--color-bg-header': 'var(--color-base-white)',
        '--color-text-header': 'var(--color-text)',
        '--color-text-search-header': 'var(--color-base-temp-blue-10)',
        '--color-top-logo-mobile': 'var(--color-base-temp-blue-7)',
    },
};

export default headerThemes;
