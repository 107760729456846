import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import media from '@activebrands/core-web/config/media';
import { styled } from '@activebrands/core-web/libs/styletron';
import Button from 'components/buttons/Button';
import ArrowIcon from 'components/icons/ArrowIcon';

const Wrapper = styled('div', {
    position: 'relative',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transitionDuration: 'unset',
    transitionProperty: 'unset',
    transitionTimingFunction: 'unset',
    margin: '16px 0',

    [media.min['desktop.sm']]: {
        marginTop: 'unset',
        marginBottom: '16px',
        padding: 'unset',
    },
});
const OverlayHeadingWrapper = styled('div', {
    [media.min['desktop.sm']]: {
        position: 'absolute',
        backgroundColor: 'var(--color-bg-navigation-labels)',
        color: 'var(--color-text-navigation-labels)',
        padding: '0px 12px',
        display: 'flex',
        alignItems: 'center',
        left: 0,
        top: 0,
        height: '24px',

        '::after': {
            content: '" "',
            position: 'absolute',
            right: '-12px',
            top: 0,
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '24px 12px 0 0',
            borderColor: 'var(--color-bg-navigation-labels) transparent transparent transparent',
        },
    },
});

const OverlayHeading = ({ firstLevel, menuValue, setMenuValue, text = '' }) => (
    <Wrapper>
        <OverlayHeadingWrapper>
            <Button
                $style={{
                    marginRight: '8px',
                    transitionDuration: 'unset',
                    transitionProperty: 'unset',
                    transitionTimingFunction: 'unset',
                    display: 'flex',
                    alignItems: 'center',
                    ...textStyles['Primary/14_100_-3'],
                }}
                onClick={() => setMenuValue({ current: firstLevel ? null : menuValue.prev, prev: menuValue.current })}
            >
                <ArrowIcon
                    $style={{
                        display: ['block', null, null, null, null, 'none'],
                        transform: 'rotate(180deg)',
                        marginRight: '8px',
                        marginBottom: '2px', // Properly align the icon with text
                        color: 'var(--color-bg-navigation-labels)',
                    }}
                    size="12px"
                />
                {text}
            </Button>
        </OverlayHeadingWrapper>
    </Wrapper>
);

OverlayHeading.propTypes = {
    firstLevel: PropTypes.bool,

    menuValue: PropTypes.object,
    setMenuValue: PropTypes.func,
    text: PropTypes.string,
};

export default OverlayHeading;
