import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';

const Badge = styled('p', {
    whiteSpace: 'nowrap',
    color: 'var(--color-text-badge)',
});

const themes = {
    'brand-primary': {
        background: 'var(--color-bg-badge-brand-primary)',
        color: 'var(--color-text-badge-brand-primary)',
    },
    'accent': {
        background: 'var(--color-bg-badge-accent)',
        color: 'var(--color-text-badge-accent)',
    },
};

const Badges = ({
    $badgesStyle = {},
    $style = {},
    limit = 2,
    items = [],
    fontKeys = 'Primary/12_100_-1',
    overrideTheme,
}) => {
    const [css] = useStyletron();

    if (!items?.length) {
        return null;
    }

    const slicedItems = items.slice(0, limit);

    return (
        <div className={css({ display: 'flex', ...$style })}>
            {slicedItems.map((badge, index) => (
                <Fragment key={`${badge.displayText}-${index}`}>
                    <div
                        className={css({
                            backgroundColor: index === 0 ? 'var(--color-bg-badge)' : 'none',
                            padding: '4px 8px',
                            display: 'flex',
                            alignItems: 'center',
                        })}
                    >
                        <Badge
                            $style={{
                                ...themes[overrideTheme || badge.theme],
                                ...responsiveTextStyles(fontKeys),
                                ...$badgesStyle,
                                ...badge.style,
                            }}
                        >
                            {badge.displayText}
                        </Badge>
                    </div>
                    {index === 0 && (
                        // triangle for design purpose
                        <div
                            className={css({
                                width: 0,
                                height: 0,
                                borderRight: '10px solid transparent',
                                borderTop: '20px solid var(--color-bg-badge)',
                            })}
                        />
                    )}
                </Fragment>
            ))}
        </div>
    );
};

Badges.propTypes = {
    $badgesStyle: PropTypes.object,
    $style: PropTypes.object,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            colorTheme: PropTypes.string,
            displayText: PropTypes.string,
            internalName: PropTypes.string,
            style: PropTypes.object,
        })
    ).isRequired,
    limit: PropTypes.number,
    overrideTheme: PropTypes.oneOf(['brand-primary', 'accent']),
};

export default Badges;
