import fm from 'format-message';
import PropTypes from 'prop-types';
import ThemeButton from 'components/buttons/ThemeButton';

const CloseButton = ({ $style = {}, handleOnClick }) => (
    <ThemeButton
        $style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            height: '40px',
            transform: 'translate3d(-50%, -50%, 0)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...$style,
        }}
        fontKeys="Primary/16_100_-03"
        type="button"
        theme="outlined"
        onClick={() => handleOnClick()}
    >
        {fm('Close')}
    </ThemeButton>
);

CloseButton.propTypes = {
    $style: PropTypes.object,
    handleOnClick: PropTypes.func.isRequired,
};

export default CloseButton;
