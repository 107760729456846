import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import { useDidUpdate } from '@activebrands/core-web/hooks/lifecycle';
import useOnClickOutside from '@activebrands/core-web/hooks/useClickOutside';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import InputErrors from 'components/Form/InputErrors';
import { Label } from 'components/inputs/Input';

const Wrapper = styled('div', {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
});

const Input = styled('input', ({ $width }) => ({
    background: 'none',
    borderStyle: 'none',
    outline: 'none',
    font: 'inherit',
    color: 'inherit',
    width: $width,
    margin: '0 8px',
    padding: '0px',
    textAlign: 'center',

    ':first-of-type': {
        marginLeft: 0,
    },
    ':last-of-type': {
        marginRight: 0,
    },
}));

const isValid = (key, event) => {
    if (!Object.values(key).some(Boolean)) {
        return false;
    }

    if (key.isNumeric) {
        const number = parseInt(event.key, 10);
        const value = parseInt(event.target.value, 10);
        const { length } = event.target.value;

        switch (event.target.name) {
            case 'year':
                if ((length === 0 && ![1, 2].includes(number)) || (length === 1 && ![0, 9].includes(number))) {
                    return false;
                }
                break;
            case 'month':
                if (length === 0 && number > 1) {
                    return false;
                }
                if (length === 1) {
                    if (value === 0 && number === 0) {
                        return false;
                    }
                    if (value === 1 && number > 2) {
                        return false;
                    }
                }
                break;
            case 'day':
                if (length === 0 && number > 3) {
                    return false;
                }
                if (length === 1 && value === 3 && number > 1) {
                    return false;
                }
                break;
            default:
                break;
        }
    }

    return true;
};

const getKey = key => ({
    arrowLeft: key === 'ArrowLeft',
    arrowRight: key === 'ArrowRight',
    backspace: key === 'Backspace' || key === 'Delete',
    tab: key === 'Tab',
    isNumeric: !isNaN(key),
});

const onKeyDown = event => {
    const { target } = event;
    const { length } = target.value;
    const nextInput = target.nextElementSibling;
    const prevInput = target.previousElementSibling;
    const key = getKey(event.key);

    if (!isValid(key, event)) {
        event.preventDefault();
        return;
    }

    if (prevInput) {
        if ((key.backspace && length === 0) || (key.arrowLeft && target.selectionStart === 0)) {
            prevInput.focus();
            setTimeout(() => prevInput.setSelectionRange(prevInput.value.length, prevInput.value.length), 0);
        }
    }

    if (nextInput) {
        if (key.arrowRight && target.selectionStart === length) {
            nextInput.focus();
            setTimeout(() => nextInput.setSelectionRange(0, 0), 0);
        }
    }
};

const onKeyUp = ({ target, ...event }) => {
    const { length } = target.value;
    const nextInput = target.nextElementSibling;
    const key = getKey(event.key);

    if (nextInput && key.isNumeric && length === target.maxLength && !nextInput.value.length) {
        nextInput.focus();
    }
};

const DateInput = ({
    $style = {},
    defaultValue,
    errors = [],
    label,
    labelStyle = {},
    name,
    onChange,
    required = false,
    value,
}) => {
    const [css] = useStyletron();
    const wrapper = useRef(null);
    const hidden = useRef(null);
    const internalValue = useRef(defaultValue ? defaultValue.split('T')[0].split('-') : []);
    const [state, setState] = useState({
        value: defaultValue ? defaultValue.split('T')[0].split('-') : [],
        isEditing: false,
    });

    useOnClickOutside(wrapper, () => {
        if (state.isEditing) {
            const value = internalValue.current;

            setState({ value, isEditing: false });

            // @todo: Dirty hack/usage of react internals to trigger onChange on hidden input.
            hidden.current._valueTracker.setValue(value);
            hidden.current.dispatchEvent(new Event('input', { bubbles: true }));
        }
    });

    useDidUpdate(() => {
        if (value !== state.value) {
            setState(prev => ({ ...prev, value }));
        }
    }, [value]);

    const handleClick = () => {
        if (!state.isEditing) {
            setState(prev => ({ ...prev, isEditing: true }));
            setTimeout(() => wrapper.current.firstChild.firstChild.focus(), 0);
        }
    };

    const handleChange = ({ target }) => {
        switch (target.name) {
            case 'year':
                return (internalValue.current[0] = target.value);
            case 'month':
                return (internalValue.current[1] = target.value);
            case 'day':
                return (internalValue.current[2] = target.value);
            default:
                break;
        }
    };

    const props = {
        maxLength: 2,
        onChange: handleChange,
        onKeyDown,
        onKeyUp,
        type: 'tel',
    };

    const _labelStyle = {
        ...labelStyle,
        top: 'calc(-100% + 35px)',
    };

    return (
        <Wrapper
            $style={{ ...$style, cursor: !state.isEditing ? 'pointer' : 'initial' }}
            ref={wrapper}
            onClick={handleClick}
        >
            <div
                className={css({
                    border: '1px solid var(--color-border-input)',
                    padding: '8px',
                    borderRadius: '2px',
                    height: '32px',
                    ...textStyles['Primary/16_100_-05'],
                })}
            >
                <Input
                    {...props}
                    $width="4.6rem"
                    defaultValue={state.value[0]}
                    maxLength={4}
                    name="year"
                    placeholder="yyyy"
                />
                /
                <Input {...props} $width="3.2rem" defaultValue={state.value[1]} name="month" placeholder="mm" />
                /
                <Input {...props} $width="3.2rem" defaultValue={state.value[2]} name="day" placeholder="dd" />
            </div>

            <Label $required={required} $style={_labelStyle}>
                {label}
            </Label>
            <input
                name={name}
                pattern="\d{4}-\d{2}-\d{2}"
                ref={hidden}
                required={required}
                style={{ display: 'none' }}
                type="text"
                value={state.value.join('-')}
                onChange={onChange}
            />
            {errors.length > 0 && <InputErrors errors={errors} />}
        </Wrapper>
    );
};

DateInput.propTypes = {
    $style: PropTypes.object,
    defaultValue: PropTypes.string,
    errors: PropTypes.array,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    value: PropTypes.string,
};

export default DateInput;
