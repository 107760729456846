import { graphql, useStaticQuery } from 'gatsby';

const useTeamStoreUrlQuery = () => {
    const { application } = useStaticQuery(
        graphql`
            {
                application {
                    data {
                        config {
                            dedicatedPages {
                                teamStoreCategoryPage
                                teamStoreProductPage
                            }
                        }
                    }
                }
            }
        `
    );
    return application.data.config.dedicatedPages || {};
};

export default useTeamStoreUrlQuery;
