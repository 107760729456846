import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import Image from '@activebrands/core-web/components/Image';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import StateIcon from 'components/icons/StateIcon';
import Paragraph from 'components/text/Paragraph';

const MenuItem = styled('div', {
    width: '100%',
    wordWrap: 'break-word',
});
const SelectorWrapper = styled('div', {
    width: '100%',
    padding: ['0', null, '12px 0', '0'],
});
const SelectButton = styled('button', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    lineHeight: '100%',
    padding: '12px var(--margin-content)',

    [media.min['tablet.sm']]: {
        borderBottom: '0',
        padding: '12px',
    },

    [media.min['desktop.sm']]: {
        padding: '12px 24px 12px 16px',
    },
});

const SiteSelectorButton = ({ $style = {}, alternateHrefs = [] }) => {
    const [css] = useStyletron();
    const { siteSelectorLinks } = useSiteSelectorQuery();
    const aliasId = useSelector(state => state.application.aliasId);
    const currentSite = siteSelectorLinks.find(site => site.id == aliasId);

    return (
        <MenuItem {...$style}>
            <SelectorWrapper>
                <SelectButton
                    onClick={() => {
                        overlay.open('site-selector', { alternateHrefs });
                    }}
                >
                    <div
                        className={css({
                            display: 'flex',
                        })}
                    >
                        <div
                            className={css({
                                marginRight: '16px',
                                borderRadius: '60px',
                                width: '16px',
                                height: '16px',
                                overflow: 'hidden',
                            })}
                            src={currentSite?.icon}
                        >
                            <Image sizes={['24px']} src={{ url: currentSite?.icon, width: [16, 48, 72] }} />
                        </div>
                        <Paragraph fontKeys="Primary/14_100_-3">{currentSite?.label}</Paragraph>
                    </div>
                    <div>
                        <StateIcon size="12px" state="plus" />
                    </div>
                </SelectButton>
            </SelectorWrapper>
        </MenuItem>
    );
};

SiteSelectorButton.propTypes = {
    $style: PropTypes.object,
    alternateHrefs: PropTypes.array,
};

export default SiteSelectorButton;
