import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Price from '@activebrands/core-web/components/Price';
import media from '@activebrands/core-web/config/media';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ColorSwatches from 'components/product/ColorSwatches';
import Heading from 'components/text/Heading';
import { Link } from 'gatsby';

const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 12px 0 0',

    [media.min['desktop.sm']]: {
        padding: '16px 12px 0 0',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
});

const GridCardBottomSection = ({ $style = {}, color, name, price, relatedVariations, uri }) => {
    const [css] = useStyletron();
    const showPrice = price?.salePriceWithCurrency && price?.priceWithCurrency;

    return (
        <Wrapper $style={$style}>
            <Link
                className={css({
                    alignSelf: 'start',
                    marginBottom: ['4px', null, null, null, null, '12px'],
                    width: ['auto', null, null, null, null, '70%'],
                })}
                title={name}
                to={uri}
            >
                <Heading fontKeys="Secondary/14_130_500">{name}</Heading>
            </Link>
            {showPrice && (
                <Price
                    $style={{
                        marginBottom: '12px',
                        width: ['auto', null, null, null, null, '30%'],
                        textAlign: ['left', null, null, null, null, 'right'],
                    }}
                    activePrice={price?.salePriceWithCurrency}
                    fontStyling={{ ...textStyles['Misc/12_100_-1_500_UC'] }}
                    originalPrice={price?.priceWithCurrency}
                />
            )}
            {relatedVariations.length > 1 && (
                <ColorSwatches
                    $style={{
                        width: ['auto', null, null, null, null, '30%'],
                        justifyContent: ['start', null, null, null, null, 'end'],
                    }}
                    currentColor={color}
                    variations={relatedVariations}
                    textStyles="Misc/10_100_500_UC"
                />
            )}
        </Wrapper>
    );
};

GridCardBottomSection.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.object,
    relatedVariations: PropTypes.array,
    uri: PropTypes.string,
};

export default GridCardBottomSection;
