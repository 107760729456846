import PropTypes from 'prop-types';
import useTeamStoreUrlQuery from 'queries/useTeamStoreUrlQuery';
import SVG from '@activebrands/core-web/components/SVG';
import { useLocation } from '@reach/router';

const TeamStoreIcon = ({
    $style = {},
    color = 'currentColor',
    size = '18px',
    viewBox = '0 0 16 16',
    isOutlined,
    ...rest
}) => {
    const style = { ...$style };
    const { pathname } = useLocation();
    const { teamStoreCategoryPage: teamStoreCategoryPagePath } = useTeamStoreUrlQuery();

    if (pathname === teamStoreCategoryPagePath && !isOutlined) {
        return (
            <SVG $style={style} width={size} height="auto" fill="none" viewBox={viewBox} {...rest}>
                <circle cx="12" cy="5" r="2.50012" fill="black" stroke="white" />
                <circle cx="4" cy="5" r="2.50012" fill="black" stroke="white" />
                <path
                    stroke="white"
                    d="M0.500122 10C0.500122 8.61936 1.61936 7.50012 3 7.50012H5C6.38064 7.50012 7.49988 8.61936 7.49988 10V15.4999H0.500122V10Z"
                    fill="black"
                />
                <path
                    stroke="white"
                    d="M8.50012 10C8.50012 8.61936 9.61936 7.50012 11 7.50012H13C14.3806 7.50012 15.4999 8.61936 15.4999 10V15.4999H8.50012V10Z"
                    fill="black"
                />
                <circle cx="8" cy="3" r="2.5" fill="black" stroke="white" />
                <path
                    stroke="white"
                    d="M11 15.5H11.5V15V8C11.5 6.61929 10.3807 5.5 9 5.5H7C5.61929 5.5 4.5 6.61929 4.5 8V15V15.5H5H11Z"
                    fill="black"
                />
            </SVG>
        );
    }

    return (
        <SVG $style={style} width={size} height="auto" fill="none" viewBox={viewBox} {...rest}>
            <circle cx="12" cy="5" r="2.50012" fill="white" stroke={color} />
            <circle cx="4" cy="5" r="2.50012" fill="white" stroke={color} />
            <path
                stroke={color}
                d="M0.500122 10C0.500122 8.61936 1.61936 7.50012 3 7.50012H5C6.38064 7.50012 7.49988 8.61936 7.49988 10V15.4999H0.500122V10Z"
                fill="white"
            />
            <path
                stroke={color}
                d="M8.50012 10C8.50012 8.61936 9.61936 7.50012 11 7.50012H13C14.3806 7.50012 15.4999 8.61936 15.4999 10V15.4999H8.50012V10Z"
                fill="white"
            />
            <circle cx="8" cy="3" r="2.5" fill="white" stroke={color} />
            <path
                stroke={color}
                d="M11 15.5H11.5V15V8C11.5 6.61929 10.3807 5.5 9 5.5H7C5.61929 5.5 4.5 6.61929 4.5 8V15V15.5H5H11Z"
                fill="white"
            />
        </SVG>
    );
};

TeamStoreIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    isOutlined: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    viewBox: PropTypes.string,
};

export default TeamStoreIcon;
