/* eslint-disable react/require-default-props */ // Gives default props error when using forwardRef

import { forwardRef, useEffect, useRef, useState } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import media from '@activebrands/core-web/config/media';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';

const Wrapper = styled('div', {
    position: 'absolute',
    top: 0,
    borderStyle: 'none',
    zIndex: 6,
    ...textStyles['Primary/16_100_-05'],

    [media.min['desktop.sm']]: {
        position: 'relative',
        borderTop: 'none',
        overflow: 'hidden',
        borderRadius: '0 0 2px 2px',
    },
});

const CallingCodeSelectMobile = styled('select', {
    height: '100%',
    marginLeft: '8px',
    opacity: 0,

    [media.min['desktop.sm']]: {
        display: 'none',
        zIndex: -1,
    },
});

const CallingCodeSelectDesktop = styled('button', {
    display: 'none',

    [media.min['desktop.sm']]: {
        display: 'initial',
        width: '100%',
        textAlign: 'left',
        overflow: 'hidden',
    },
});

const SearchWrapper = styled('div', {
    display: 'flex',
    alignItems: 'flex-start',
});

const FilterInput = styled('input', {
    width: '100%',
    paddingBottom: '14px',
    borderWidth: '0',
    background: 'none',
    ...textStyles['Primary/16_100_-05'],

    ':focus': {
        outline: 'none',
    },
});

const OptionsDesktop = styled(ScrollableBox, {
    flexDirection: 'column',
    maxHeight: '177px',
});

const Option = styled('p', {
    listStyle: 'none',
    cursor: 'pointer',
    padding: '6px 0',

    ':focus': {
        outline: '0',
        background: 'rgba(0,0,0,0.05)',
    },

    ':hover': {
        outline: '0',
        background: 'rgba(0,0,0,0.05)',
    },
});

const CallingCodeSelect = forwardRef(
    ({ $style, callingCodes = [], handleClick = () => null, isSelectOpen = false, mobileSelectWidth }, ref) => {
        const [css] = useStyletron();
        const filterInput = useRef();

        const [state, setState] = useState({
            needle: '',
            filter: '',
        });

        const { needle, filter } = state;

        if (isSelectOpen && filterInput.current) {
            filterInput.current.focus();
        }

        if (!isSelectOpen && needle.length) {
            setState({ ...state, needle: '' });
        }

        useEffect(() => {
            // Delay input change so we don't do it while animating
            const timeOutId = setTimeout(() => setState({ ...state, filter: needle }), 400);
            return () => clearTimeout(timeOutId);
        }, [needle]);

        if (!callingCodes.length) {
            return null;
        }

        // Filters by country or iso code
        const filterThroughOptions = option =>
            option.country.toLowerCase().indexOf(filter) >= 0 || option.callingCode.indexOf(filter) >= 0;

        return (
            <Wrapper
                $style={{
                    height: isSelectOpen ? 'auto' : ['100%', null, null, null, null, 0],
                    borderColor: isSelectOpen ? 'var(--color-border-select)' : 'none',
                    borderStyle: isSelectOpen ? 'solid' : 'none',
                    borderWidth: isSelectOpen ? '1px' : 'none',
                    ...$style,
                }}
            >
                <CallingCodeSelectMobile
                    $style={{ width: `${mobileSelectWidth}px` }}
                    onChange={e => {
                        const callingCode =
                            e.target.options?.[e.target.selectedIndex].getAttribute('data-calling-code');
                        const isoCode = e.target.options?.[e.target.selectedIndex].getAttribute('data-iso-code');

                        handleClick(isoCode, callingCode);
                    }}
                >
                    {callingCodes.map(({ callingCode, isoCode, country }) => (
                        <option data-calling-code={callingCode} data-iso-code={isoCode} key={isoCode}>
                            {country} ({callingCode})
                        </option>
                    ))}
                </CallingCodeSelectMobile>
                <CallingCodeSelectDesktop ref={ref} type="button">
                    <div className={css({ padding: '8px' })}>
                        <SearchWrapper>
                            <FilterInput
                                placeholder={fm('Select an option or search...')}
                                ref={filterInput}
                                value={needle}
                                onChange={e => setState({ ...state, needle: e.target.value?.toLowerCase() })}
                            />
                        </SearchWrapper>
                        <OptionsDesktop withScrollbar>
                            {callingCodes
                                .filter(option => (filter.length ? filterThroughOptions(option) : true))
                                .map(({ callingCode, isoCode, country }) => (
                                    <Option
                                        key={isoCode}
                                        tabIndex={isSelectOpen ? '0' : '-1'}
                                        onClick={() => handleClick(isoCode, callingCode)}
                                    >
                                        {country} ({callingCode})
                                    </Option>
                                ))}
                            {callingCodes.filter(option => (filter.length ? filterThroughOptions(option) : true))
                                .length === 0 ? (
                                <p>{fm('No available options')}</p>
                            ) : null}
                        </OptionsDesktop>
                    </div>
                </CallingCodeSelectDesktop>
            </Wrapper>
        );
    }
);

CallingCodeSelect.displayName = 'CallingCodeSelect';

CallingCodeSelect.propTypes = {
    $style: PropTypes.object,
    callingCodes: PropTypes.arrayOf(
        PropTypes.shape({
            callingCode: PropTypes.string,
            isoCode: PropTypes.string,
            country: PropTypes.string,
        })
    ),
    handleClick: PropTypes.func,
    isSelectOpen: PropTypes.bool,
    mobileSelectWidth: PropTypes.number,
};

export default CallingCodeSelect;
