import { useRef, useState } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import headerThemes from 'config/branding/component-themes/header-themes';
import textStyles from 'config/branding/textStyles';
import useHeaderNavigationQuery from 'queries/useHeaderNavigationQuery';
import useTeamStoreUrlQuery from 'queries/useTeamStoreUrlQuery';
import Link from '@activebrands/core-web/components/Link';
import AnimatedOverlay from '@activebrands/core-web/components/overlays/AnimatedOverlay';
import media from '@activebrands/core-web/config/media';
import { StateProvider } from '@activebrands/core-web/contexts/StateContext';
import overlay, { useOverlaySubscriber } from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import loadable from '@loadable/component';
import AuthLink from 'components/Header/AuthLink';
import Logotype from 'components/Header/Logotype';
import Navigation from 'components/Header/Navigation';
import PrimaryLevelOneDesktop from 'components/Header/Navigation/PrimaryMenu/PrimaryLevelOneDesktop';
import SecondaryLevelOneDesktop from 'components/Header/Navigation/SecondaryMenu/SecondaryLevelOneDesktop';
import OverlayToggler from 'components/Header/OverlayToggler';
import SearchInput from 'components/Header/SearchInput';
import BasketCounterIcon from 'components/icons/BasketCounterIcon';
import CloseIcon from 'components/icons/CloseIcon';
import MenuIcon from 'components/icons/MenuIcon';
import SearchIcon from 'components/icons/SearchIcon';
import TeamStoreIcon from 'components/icons/TeamStoreIcon';
import Paragraph from 'components/text/Paragraph';
import { useStyletron } from 'styletron-react';
import CloseOverlay from './CloseButton';

const SearchOverlay = loadable(() => import('components/Header/SearchOverlay'));
const BasketOverlay = loadable(() => import('components/Header/BasketOverlay'));
const ENABLE_TEAMSTORE = process.env.REACT_APP_ENABLE_TEAMSTORE;

// Background color for HeaderElement is dynamic and is set in DefaultLayout.js
const HeaderElement = styled('header', ({ $headerOverlayIsOpen }) => ({
    alignItems: 'center',
    borderTop: $headerOverlayIsOpen ? 'unset' : '1px solid var(--color-border-header)',
    bottom: 0,
    color: 'var(--color-text-header)',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    transition: 'color, background-color var(--duration-fast) var(--ease)',
    zIndex: 'var(--zindex-header)',

    [media.min['desktop.sm']]: {
        position: 'fixed',
        top: 0,
        bottom: 'auto',
        borderTop: 'none',
        padding: '4px 4px 0 4px',
    },
}));

const InnerHeaderWrapper = styled('div', {
    position: 'relative',
    width: '100%',
    height: 'var(--height-header)',
    backgroundColor: 'var(--color-bg-header)',
    padding: ['0 4px', null, null, null, 0],

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const IconWrapper = styled('div', {
    display: 'flex',
    gap: '8px',
    [media.min['desktop.sm']]: {
        gap: 'unset',
    },
});

const Header = ({
    $style = {},
    alternateHrefs = [],
    backToSiteLabel = '',
    isCheckout = false,
    isEmptyCheckout = false,
    previewDataOverride,
    theme = 'default',
}) => {
    const headerRef = useRef(null);
    const [css] = useStyletron();
    const headerConfigContent = useHeaderNavigationQuery();
    const { teamStoreCategoryPage: teamStoreCategoryPagePath } = useTeamStoreUrlQuery();

    const pageData = previewDataOverride || headerConfigContent;
    const { primaryMenu, secondaryMenu = [] } = pageData;
    const [menuValue, setMenuValue] = useState({ current: null, prev: null });

    const openOverlays = useOverlaySubscriber();

    const headerOverlays = [
        'account-navigation',
        'basket',
        'category-facet-groups',
        'category-sort-options',
        'navigation',
        'not-within-team-ordering-period',
        'quickshop',
        'removed-team-products-from-basket',
        'search-facet-groups',
        'search-sort-options',
        'search',
        'shop-the-look',
        'sizeguide',
        'site-selector',
        'unauthenticated',
        'validate-team-account',
        'validate-team-account-success',
        'wrong-site-overlay',
    ];

    const headerOverlayIsOpen = headerOverlays.includes(openOverlays[0]);

    const resetNavigation = () => {
        const navigation = document.getElementById('navigation');
        navigation.scrollTop = 0;
        setMenuValue({ current: null, prev: null });
        overlay.close('navigation');
    };

    const checkoutHeaderStyling = {
        width: '100%',
        maxWidth: isEmptyCheckout ? 'none' : 'var(--checkout-corridor-max-width)',
        borderBottom: ['none', null, null, null, null, '1px solid var(--color-border-checkout-subtle)'],
        borderTop: ['1px solid var(--color-border-checkout-subtle)', null, null, null, null, 'none'],
        borderLeft: '1px solid var(--color-border-checkout-subtle)',
        borderRight: '1px solid var(--color-border-checkout-subtle)',
        backgroundColor: 'var(--color-bg-header)',
        ...headerThemes['checkout'],
    };

    const headerStyling = isCheckout ? checkoutHeaderStyling : headerThemes[theme];

    return (
        <StateProvider key="SearchProvider">
            <div className={css({ display: 'flex', justifyContent: 'center' })}>
                <HeaderElement
                    $style={{ ...headerStyling, ...$style }}
                    $headerOverlayIsOpen={headerOverlayIsOpen}
                    ref={headerRef}
                >
                    <InnerHeaderWrapper>
                        {isCheckout && (
                            <>
                                <Link
                                    className={css({
                                        display: 'flex',
                                        height: '40px',
                                        alignItems: 'center',
                                        padding: '12px',
                                    })}
                                    to="/"
                                >
                                    <Paragraph fontKeys={['Primary/14_100_-3']}>{backToSiteLabel}</Paragraph>
                                </Link>
                                <Logotype $style={{ display: 'inline-flex', justifyContent: 'center' }} height="40px" />
                                <Paragraph
                                    $style={{ color: 'var(--color-text-subtle)', padding: '12px' }}
                                    fontKeys="Primary/14_100_-3"
                                >
                                    {fm('Checkout')}
                                </Paragraph>
                            </>
                        )}
                        {!isCheckout && (
                            <div
                                className={css({
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    height: '100%',
                                })}
                            >
                                <div
                                    className={css({
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    })}
                                >
                                    <OverlayToggler $style={{ height: 'fit-content' }} overlayId="navigation">
                                        {isOpen =>
                                            isOpen ? (
                                                <CloseIcon
                                                    $style={{
                                                        width: ['24px', null, null, null, null, '16px'],
                                                        height: ['24px', null, null, null, null, '16px'],
                                                    }}
                                                />
                                            ) : (
                                                <MenuIcon
                                                    $style={{
                                                        width: ['24px', null, null, null, null, '16px'],
                                                        height: ['24px', null, null, null, null, '16px'],
                                                    }}
                                                />
                                            )
                                        }
                                    </OverlayToggler>
                                    <PrimaryLevelOneDesktop
                                        items={primaryMenu}
                                        menuValue={menuValue}
                                        resetNavigation={resetNavigation}
                                        setMenuValue={setMenuValue}
                                    />
                                    <Navigation
                                        alternateHrefs={alternateHrefs}
                                        menuValue={menuValue}
                                        pageData={pageData}
                                        resetNavigation={resetNavigation}
                                        setMenuValue={setMenuValue}
                                    />
                                    <OverlayToggler
                                        $style={{
                                            height: 'fit-content',
                                            display: ['block', null, null, null, null, 'none'],
                                            marginRight: 'auto',
                                        }}
                                        overlayId="search"
                                        type="button"
                                    >
                                        <SearchIcon
                                            $style={{
                                                display: 'block',
                                                width: ['24px', null, null, null, null, '16px'],
                                                height: ['24px', null, null, null, null, '16px'],
                                            }}
                                        />
                                    </OverlayToggler>
                                </div>
                                <div
                                    className={css({ display: 'flex', justifyContent: 'center', alignItems: 'center' })}
                                >
                                    <SecondaryLevelOneDesktop
                                        items={secondaryMenu}
                                        menuValue={menuValue}
                                        resetNavigation={resetNavigation}
                                        setMenuValue={setMenuValue}
                                    />
                                    <SearchInput
                                        $style={{
                                            display: ['none', null, null, null, null, 'flex'],
                                            margin: '0 16px',
                                        }}
                                        previewDataOverride={previewDataOverride}
                                    />
                                    <IconWrapper>
                                        {ENABLE_TEAMSTORE === 'true' && (
                                            <AuthLink
                                                isNoFollow
                                                $style={{
                                                    ...textStyles['Primary/18_100_-05'],
                                                    display: ['none', null, null, null, null, 'flex'],
                                                }}
                                                linkText="Team Store"
                                                Icon={TeamStoreIcon}
                                                path={teamStoreCategoryPagePath}
                                            />
                                        )}
                                        <AuthLink />
                                        <OverlayToggler overlayId="basket">
                                            <BasketCounterIcon />
                                        </OverlayToggler>
                                    </IconWrapper>
                                </div>
                                {headerOverlayIsOpen && (
                                    <CloseOverlay
                                        $style={{
                                            display: ['block', null, null, null, 'none'],
                                            padding: '0 34px',
                                        }}
                                        handleOnClick={() => {
                                            resetNavigation();
                                            overlay.closeAll();
                                        }}
                                    />
                                )}
                                <Logotype
                                    $style={{
                                        display: [
                                            headerOverlayIsOpen ? 'none' : 'inline-flex',
                                            null,
                                            null,
                                            null,
                                            null,
                                            'inline-flex',
                                        ],
                                    }}
                                    width="47px"
                                />
                            </div>
                        )}
                    </InnerHeaderWrapper>
                </HeaderElement>
            </div>
            <AnimatedOverlay
                lockScroll
                $style={{
                    top: ['0', null, null, null, 'var(--height-header)'],
                    height: ['100vh', null, null, null, '100%'],
                }}
                component={BasketOverlay}
                id="basket"
            />
            <AnimatedOverlay lockScroll component={SearchOverlay} id="search" />
        </StateProvider>
    );
};

Header.propTypes = {
    $style: PropTypes.object,
    alternateHrefs: PropTypes.array,
    backToSiteLabel: PropTypes.string,
    isCheckout: PropTypes.bool,
    isEmptyCheckout: PropTypes.bool,
    previewDataOverride: PropTypes.object,
    theme: PropTypes.string.isRequired,
};

export default Header;
