import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import { styled } from '@activebrands/core-web/libs/styletron';
import errorMessages from '@activebrands/core-web/utils/auth/error-messages';
import isObject from '@grebban/utils/object/isObject';

const ErrorMessages = styled('p', {
    width: '100%',
    marginTop: '4px',
    color: 'var(--color-text-error)',
    ...textStyles['Primary/12_100_-1'],

    ':before': {
        content: '"*"',
    },
});

const InputErrors = ({ errors, $style }) => {
    return errors.map((error, i) => {
        let message = errorMessages[error.error] || errorMessages[error] || error.message;

        if (!message && isObject(error)) {
            message = Object.values(error).join(', ');
        }

        return (
            <ErrorMessages $style={$style} key={i}>
                {message}
            </ErrorMessages>
        );
    });
};

InputErrors.propTypes = {
    $style: PropTypes.object,
    errors: PropTypes.array.isRequired,
};

export default InputErrors;
