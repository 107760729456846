import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const PlusIcon = ({ $style = {}, color = 'currentColor', viewBox = '0 0 24 24', size = '24px', ...rest }) => {
    const style = { ...$style };
    return (
        <SVG $style={style} width={size} height="auto" viewBox={viewBox} fill="none" {...rest}>
            <path d="M12 0V24" stroke={color} />
            <path d="M24 12L0 12" stroke={color} />
        </SVG>
    );
};

PlusIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    viewBox: PropTypes.string,
};

export default PlusIcon;
