import PropTypes from 'prop-types';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import media from '@activebrands/core-web/config/media';
import transformFlexibleBackgrounds from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-backgrounds';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import getTextColorStyles from '@activebrands/core-web/utils/style-functions/get-text-color-styles';
import ButtonsList from 'components/buttons/ButtonsList';
import StateButton from 'components/buttons/StateButton';
import Heading from 'components/text/Heading';
import Tag from 'components/text/Tag';
import { Link } from 'gatsby';

const Wrapper = styled('div', {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [media.min['desktop.sm']]: {
        flexDirection: 'row',
    },
});

const ContentWrapper = styled('div', {
    position: 'absolute',
    zIndex: 'var(--zindex-middleground)',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const BottomContent = styled('div', {
    padding: '0 16px 16px',
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',

    [media.min['tablet.lg']]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0 24px 24px',
    },
});

const BackgroundWrapper = styled('div', {
    [media.min['desktop.sm']]: {
        width: '100%',
        order: 1,
        height: '100%',
    },
});

const BackgroundInnerWrapper = styled('div', {
    position: 'relative',
    aspectRatio: 'var(--ratio-vertical-secondary)',
    maxHeight: 'calc(100vh - var(--height-header))',
    objectFit: 'cover',
    width: '100vw',

    [media.min['desktop.sm']]: {
        aspectRatio: 'var(--ratio-horizontal-primary)',
        maxHeight: 'calc(100vh - var(--height-header))',
    },
});

const Category = ({
    backgroundDesktop = [],
    backgroundMobile = [],
    backgroundOverlay,
    button = {},
    buttons = [],
    heading = '',
    headingType = 'h2',
    id = '',
    tag = '',
    textColor = '',
}) => {
    const [css] = useStyletron();
    const hasButtons = buttons.length > 0;

    const transformedBackgrounds = transformFlexibleBackgrounds(
        [backgroundMobile[0], null, null, null, backgroundDesktop[0]],
        1
    );

    const textColorStyle = getTextColorStyles(textColor);

    return (
        <Wrapper id={id}>
            <ContentWrapper>
                {tag && (
                    <Tag
                        $style={{
                            position: 'absolute',
                            top: 0,
                            backgroundColor: 'var(--color-bg-tag-category-module)',
                            height: '21px',
                        }}
                    >
                        {tag}
                    </Tag>
                )}
                <div className={css({ position: 'absolute', bottom: 0, width: '100%' })}>
                    {heading && (
                        <Heading
                            $style={{
                                padding: ['0 16px 16px', null, null, '0 24px 24px'],
                                color: textColorStyle,
                            }}
                            as={headingType}
                            fontKeys="Primary/32_100_-2"
                        >
                            {heading}
                        </Heading>
                    )}
                    <BottomContent>
                        {hasButtons && (
                            <ButtonsList
                                $style={{
                                    marginLeft: ['-16px', null, '0'],
                                    marginRight: ['16px', null, '0'],
                                    padding: ['0 16px', null, '0'],
                                    width: ['calc(100% + 32px)', null, 'auto'],
                                    overflowX: ['auto', null, 'hidden'],
                                    flexWrap: ['nowrap', null, 'wrap'],
                                    '-webkit-overflow-scrolling': 'touch',

                                    // Hide scrollbar in safari and chrome
                                    '::-webkit-scrollbar': {
                                        display: 'none',
                                    },

                                    // Hide scrollbar in firefox
                                    scrollbarWidth: 'none',
                                }}
                                buttonStyling={{ padding: ['12px', null, '8px 16px'] }}
                                buttons={buttons}
                                fontKeys="Primary/16_100_-05"
                            />
                        )}

                        {button.url && button.label && (
                            <StateButton
                                $style={{
                                    width: 'fit-content',
                                    marginBottom: ['16px', null, null, null, '0px'],
                                }}
                                as={Link}
                                fontKeys="Primary/18_100_-05"
                                rel={button.rel}
                                theme={button.theme}
                                to={button.url}
                            >
                                {button.label}
                            </StateButton>
                        )}
                    </BottomContent>
                </div>
            </ContentWrapper>
            <BackgroundWrapper>
                <BackgroundInnerWrapper>
                    <BackgroundDynamic
                        background={transformedBackgrounds}
                        backgroundOverlay={backgroundOverlay}
                        loading="lazy"
                    />
                </BackgroundInnerWrapper>
            </BackgroundWrapper>
        </Wrapper>
    );
};

Category.propTypes = {
    backgroundDesktop: PropTypes.array,
    backgroundMobile: PropTypes.array,
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    button: PropTypes.shape({
        label: PropTypes.string,
        theme: PropTypes.string,
        url: PropTypes.string,
        rel: PropTypes.string,
    }),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
            rel: PropTypes.string,
        })
    ),
    heading: PropTypes.string,
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    tag: PropTypes.string,
    textColor: PropTypes.oneOf(['black', 'white']),
};

export default Category;
