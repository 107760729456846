import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Price from '@activebrands/core-web/components/Price';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ColorSwatches from 'components/product/ColorSwatches';
import Heading from 'components/text/Heading';
import { Link } from 'gatsby';

const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: '12px',
    color: 'var(--color-text-product-card)',
    gap: '8px',
});

const SingleViewCardBottomSection = ({
    $style = {},
    currentVariationId = '',
    name = '',
    price = {},
    relatedVariations = [],
    uri = '',
}) => {
    const [css] = useStyletron();
    const showPrice = price?.salePriceWithCurrency && price?.priceWithCurrency;

    return (
        <Wrapper $style={$style}>
            <div
                className={css({
                    display: ['block', null, null, null, null, 'flex'],
                    flexDirection: 'column',
                    gap: ['0px', null, null, null, null, '8px'],
                })}
            >
                <Link
                    className={css({
                        alignSelf: 'start',
                        width: 'auto',
                    })}
                    title={name}
                    to={uri}
                >
                    <Heading fontKeys="Primary/14_100_-2_500">{name}</Heading>
                </Link>
                {relatedVariations.length > 1 && (
                    <ColorSwatches
                        $style={{
                            width: '100%',
                            display: ['none', null, null, null, null, 'flex'],
                            justifyContent: 'start',
                        }}
                        textstyles="Primary/12_100_-1_500"
                        currentVariationId={currentVariationId}
                        relatedVariations={relatedVariations}
                    />
                )}
            </div>
            {showPrice && (
                <Price
                    $style={{
                        display: [null, null, null, null, null, 'flex'],
                        width: 'auto',
                        textAlign: ['left', null, null, null, null, 'right'],
                    }}
                    activePrice={price?.salePriceWithCurrency}
                    fontStyling={textStyles['Primary/14_100_-2_500']}
                    originalPrice={price?.priceWithCurrency}
                />
            )}
            {relatedVariations.length > 1 && (
                <ColorSwatches
                    $style={{
                        width: '100%',
                        display: ['flex', null, null, null, null, 'none'],
                        justifyContent: 'start',
                    }}
                    textstyles="Primary/12_100_-1_500"
                    currentVariationId={currentVariationId}
                    relatedVariations={relatedVariations}
                />
            )}
            {/* TODO: Add addtowishlist functionality
                    <HeartIcon /> */}
            {/* // @todo: add material when we have datapoint.
                <Paragraph fontKeys="Misc/10_100_500_UC">Merino woll yoga</Paragraph> */}
        </Wrapper>
    );
};

SingleViewCardBottomSection.propTypes = {
    $style: PropTypes.object,
    currentVariationId: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.object,
    relatedVariations: PropTypes.array,
    uri: PropTypes.string,
};

export default SingleViewCardBottomSection;
