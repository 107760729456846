import { useSelector } from 'react-redux';
import textStyles from 'config/branding/textStyles';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import BasketIcon from 'components/icons/BasketIcon';

const NumberBadge = styled('div', {
    position: 'absolute',
    top: 'calc(50% + 1px)', // For alignment
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
});

const Number = styled('span', {
    ...textStyles['Primary/10_70'],
});

const BasketCounterIcon = () => {
    const [css] = useStyletron();

    const basketItems = useSelector(s => s.basket.items);
    const numberOfItems = basketItems.reduce((sum, item) => (sum += item.quantity), 0);

    // @todo: We should add number badge logic to the SVG in BasketIcon.
    return (
        <div className={css({ position: 'relative', display: 'flex' })}>
            {numberOfItems > 0 && (
                <NumberBadge>
                    <Number>{numberOfItems > 99 ? 99 : numberOfItems}</Number>
                </NumberBadge>
            )}
            <BasketIcon
                $style={{
                    display: 'block',
                    width: ['24px', null, null, null, null, '16px'],
                    height: ['24px', null, null, null, null, '16px'],
                }}
            />
        </div>
    );
};

export default BasketCounterIcon;
