import { graphql, useStaticQuery } from 'gatsby';

const useAccountQuery = () => {
    const { application } = useStaticQuery(
        graphql`
            {
                application {
                    data {
                        config {
                            dedicatedPages {
                                accountOrdersPage
                                accountPage
                                accountSettingsPage
                            }
                            accountConfig {
                                internalMenu {
                                    ...LinkItem
                                }
                                externalMenu {
                                    ...LinkIcon
                                }
                                actRewardsLinkLabel
                                forgotPasswordConfirmationContent {
                                    ... on Heading {
                                        component
                                        ...Heading
                                    }
                                    ... on Paragraph {
                                        component
                                        ...Paragraph
                                    }
                                    ... on Button {
                                        component
                                        ...Button
                                    }
                                }
                                forgotPasswordContent {
                                    ... on Heading {
                                        component
                                        ...Heading
                                    }
                                    ... on Paragraph {
                                        component
                                        ...Paragraph
                                    }
                                    ... on Button {
                                        component
                                        ...Button
                                    }
                                }
                                forgotPasswordLinkLabel
                                registerConfirmationContent {
                                    ... on Heading {
                                        component
                                        ...Heading
                                    }
                                    ... on Paragraph {
                                        component
                                        ...Paragraph
                                    }
                                    ... on Button {
                                        component
                                        ...Button
                                    }
                                }
                                brands {
                                    _uid
                                    component
                                    brandCode {
                                        data {
                                            brand
                                            brandCode
                                        }
                                    }
                                    description {
                                        ...Paragraph
                                    }
                                    image {
                                        ... on BackgroundBynder {
                                            ...BackgroundBynder
                                        }
                                        ... on BackgroundImage {
                                            ...BackgroundImage
                                        }
                                    }
                                    logo {
                                        ...Icon
                                    }
                                }
                                signInLabel
                                signInPasswordInput {
                                    label
                                    placeholder
                                }
                                signInThirdPartyServiceLabel
                                signInUsernameInput {
                                    label
                                    placeholder
                                }
                                signInActMembershipLabel
                                signInActMembershipDescription
                                signInActMembershipConsent {
                                    ...Paragraph
                                }
                                signInActTosConsent {
                                    ...Paragraph
                                }
                                signUpTosLabel {
                                    ...Paragraph
                                }
                                signUpLabel
                                signUpThirdPartyServiceLabel
                                welcomeLabel
                                validateTeamAccountTosLabel {
                                    ...Paragraph
                                }
                                validateTeamAccountContent {
                                    ... on Heading {
                                        component
                                        ...Heading
                                    }
                                    ... on Paragraph {
                                        component
                                        ...Paragraph
                                    }
                                }
                                validateTeamAccountSuccessModalTitle
                                validateTeamAccountSuccessModalContent
                                validateTeamAccountSuccessModalButtonLabel
                            }
                        }
                    }
                }
            }
        `
    );

    const content = application.data.config || {};
    const transformContent = content => {
        const result: Array<object> = [];

        content?.forEach(item => {
            if (item.component === 'heading') {
                result.push({
                    component: item.component,
                    text: item.text,
                    type: item.type,
                });
            }
            if (item.component === 'paragraph') {
                result.push({
                    component: item.component,
                    text: item.richText,
                });
            }
            if (item.component === 'button') {
                result.push({
                    component: item.component,
                    follow: item.follow,
                    text: item.label,
                    link: item.link,
                });
            }
            if (item.component === 'brandBlock') {
                result.push({
                    id: item._uid,
                    brandCode: item.brandCode,
                    image: item.image,
                    logo: item.logo,
                    description: item.description?.[0]?.richText,
                });
            }
        });

        return result;
    };
    const transformedContent = {
        actRewardsLinkLabel: content.accountConfig.actRewardsLinkLabel,
        brands: transformContent(content.accountConfig.brands),
        externalMenu: content.accountConfig.externalMenu,
        forgotPasswordConfirmationContent: transformContent(content.accountConfig.forgotPasswordConfirmationContent),
        forgotPasswordContent: transformContent(content.accountConfig.forgotPasswordContent),
        forgotPasswordLinkLabel: content.accountConfig.forgotPasswordLinkLabel,
        registerConfirmationContent: transformContent(content.accountConfig.registerConfirmationContent),
        signInActMembershipConsent: content.accountConfig.signInActMembershipConsent?.[0]?.richText,
        signInActTosConsent: content.accountConfig.signInActTosConsent?.[0]?.richText,
        internalMenu: content.accountConfig.internalMenu,
        signInLabel: content.accountConfig.signInLabel,
        signInPasswordInput: content.accountConfig.signInPasswordInput,
        signInThirdPartyServiceLabel: content.accountConfig.signInThirdPartyServiceLabel,
        signInUsernameInput: content.accountConfig.signInUsernameInput,
        signUpTosLabel: content.accountConfig.signUpTosLabel?.[0]?.richText,
        signUpLabel: content.accountConfig.signUpLabel,
        signUpThirdPartyServiceLabel: content.accountConfig.signUpThirdPartyServiceLabel,
        welcomeLabel: content.accountConfig.welcomeLabel,
        signInActMembershipDescription: content.accountConfig.signInActMembershipDescription,
        signInActMembershipLabel: content.accountConfig.signInActMembershipLabel,
        validateTeamAccountTosLabel: content.accountConfig.validateTeamAccountTosLabel?.[0]?.richText,
        validateTeamAccountContent: transformContent(content.accountConfig.validateTeamAccountContent),
        validateTeamAccountSuccessModalTitle: content.accountConfig.validateTeamAccountSuccessModalTitle,
        validateTeamAccountSuccessModalContent: content.accountConfig.validateTeamAccountSuccessModalContent,
        validateTeamAccountSuccessModalButtonLabel: content.accountConfig.validateTeamAccountSuccessModalButtonLabel,
        ...application.data.config.dedicatedPages,
    };

    return {
        ...transformedContent,
    };
};

export default useAccountQuery;
