import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';

const StyledTag = styled('span', {
    position: 'relative',
    padding: '0 var(--padding-tag)',
    '::after': {
        content: '" "',
        position: 'absolute',
        right: '-12px',
        top: '-3px',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '24px 12px 0 0',
        borderColor: 'var(--color-bg-flexible-tag) transparent transparent transparent',
    },
});

const Tag = ({ $style = {}, as = 'span', children, fontKeys = 'Primary/14_100_-3', ...rest }) => {
    const fontStyle = responsiveTextStyles(fontKeys);
    const style = {
        display: 'inline-block',
        ...fontStyle,
        ...$style,
    };

    return (
        <StyledTag $style={style} as={as} {...rest}>
            {children}
        </StyledTag>
    );
};

Tag.propTypes = {
    $style: PropTypes.object,
    as: PropTypes.string,
    children: PropTypes.node,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Tag;
