import PropTypes from 'prop-types';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ArrowIcon from 'components/icons/ArrowIcon';
import CloseIcon from 'components/icons/CloseIcon';
import LogotypeFlat from 'components/icons/LogotypeFlat';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    position: 'sticky',
    top: 0,
    padding: '16px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '56px',
    borderBottom: '1px solid var(--color-border-navigation)',
    backgroundColor: 'var(--color-bg-header)',

    [media.min['desktop.sm']]: {
        borderBottom: 'unset',
        marginBottom: 'unset',
    },
});

const TextLabel = styled('div', {
    margin: '12px',

    [media.min['desktop.sm']]: {
        margin: 'unset',
        position: 'absolute',
        backgroundColor: 'var(--color-bg-navigation-labels)',
        color: 'var(--color-text-navigation-labels)',
        padding: '0px 12px',
        display: 'flex',
        alignItems: 'center',
        left: 0,
        top: 0,
        height: '24px',

        '::after': {
            content: '" "',
            position: 'absolute',
            right: '-12px',
            top: 0,
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '24px 12px 0 0',
            borderColor: 'var(--color-bg-navigation-labels) transparent transparent transparent',
        },
    },
});

const PrimaryMenuHeading = ({
    text = '',
    resetNavigation = () => null,
    toggleSiteSelector = () => null,
    isSiteSelectorOpen = false,
}) => {
    const [css] = useStyletron();

    const openPrimaryMenu = () => {
        overlay.open('navigation'), overlay.close('site-selector');
    };

    return (
        <Wrapper>
            {!!isSiteSelectorOpen ? (
                <ArrowIcon
                    $style={{
                        transform: 'rotate(-180deg)',
                        display: ['block', null, null, null, null, 'none'],
                        margin: '12px',
                        color: 'var(--color-bg-navigation-labels)',
                    }}
                    color="var(--color-bg-navigation-labels)"
                    height="16px"
                    onClick={() => toggleSiteSelector() || openPrimaryMenu()}
                />
            ) : (
                <TextLabel>
                    <Paragraph fontKeys="Misc/12_100_-1_500_UC">{text}</Paragraph>
                </TextLabel>
            )}

            <div
                className={css({
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                })}
            >
                <LogotypeFlat $style={{ display: ['block', null, null, null, null, 'none'] }} width="127px" />
            </div>

            <CloseIcon
                $style={{
                    display: ['block', null, null, null, null, 'none'],
                    position: 'absolute',
                    right: 0,
                    cursor: 'pointer',
                    margin: '12px',
                }}
                size="16px"
                state="reset"
                onClick={() => resetNavigation() || overlay.close('site-selector')}
            />
        </Wrapper>
    );
};

PrimaryMenuHeading.propTypes = {
    isSiteSelectorOpen: PropTypes.bool,
    resetNavigation: PropTypes.func,
    text: PropTypes.string,
    toggleSiteSelector: PropTypes.func,
};

export default PrimaryMenuHeading;
