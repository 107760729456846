import PropTypes from 'prop-types';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import Tag from 'components/text/Tag';
import { Link } from 'gatsby';

const Wrapper = styled('div', {
    width: '100%',
    height: '100%',
    display: ['flex', null, null, null, 'grid'],
    flexDirection: 'column',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gap: ['32px 8px', null, '32px 12px', null, '0 16px'],
});

const ImageWrapper = styled('div', {
    position: 'relative',
    aspectRatio: [
        'var(--ratio-vertical-primary)',
        null,
        'var(--ratio-horizontal-primary)',
        null,
        'var(--ratio-horizontal-secondary)',
    ],
});

const ContentWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: ['0 var(--margin-content)', null, null, null, '0'],
});

const Information = ({
    background,
    backgroundOverlay,
    buttons = [],
    contentOrder = [{ image: 1 }, { text: 2 }],
    switchDesktopOrder = false,
    heading,
    headingType = 'h2',
    headingStyle = 'md',
    id = '',
    tag,
    text,
}) => {
    const [css] = useStyletron();
    const hasButtons = buttons.length > 0;

    const headingFontKeys = {
        sm: ['Primary/24_120_-1'],
        md: ['Primary/40_110_-2'],
    };

    return (
        <Wrapper id={id}>
            <ImageWrapper
                $style={{
                    gridColumn: switchDesktopOrder ? '6 / span 7' : '1 /span 7',
                    order: contentOrder.image,
                }}
            >
                {tag && (
                    <div
                        className={css({
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            backgroundColor: 'var(--color-bg-flexible-tag)',
                            height: '24px',
                        })}
                    >
                        <Tag>{tag}</Tag>
                    </div>
                )}
                <BackgroundDynamic
                    $style={{ position: 'unset' }}
                    background={background}
                    backgroundOverlay={backgroundOverlay}
                    loading="lazy"
                />
            </ImageWrapper>
            <ContentWrapper
                $style={{
                    gridColumn: switchDesktopOrder ? '2 / span 3' : '9 / span 3',
                    order: contentOrder.text,
                }}
            >
                {heading && (
                    <Heading as={headingType} fontKeys={headingFontKeys[headingStyle]}>
                        {heading}
                    </Heading>
                )}
                {text && (
                    <Paragraph
                        $style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
                        fontKeys={['Primary/16_140_-05', null, 'Primary/18_120_-05', null, 'Primary/16_140_-05']}
                    >
                        <RichText data={text} />
                    </Paragraph>
                )}

                <div
                    className={css({
                        width: 'auto',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: ['8px 4px', null, '8px'],
                    })}
                >
                    {hasButtons &&
                        buttons.map((button, index) => (
                            <ThemeButton
                                as={Link}
                                rel={button.rel}
                                theme={button.theme}
                                to={button.url}
                                key={`${button?.label}-${index}`}
                                fontKeys="Primary/16_100_-05"
                            >
                                {button.label}
                            </ThemeButton>
                        ))}
                </div>
            </ContentWrapper>
        </Wrapper>
    );
};

Information.propTypes = {
    background: PropTypes.arrayOf(PropTypes.object),
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    contentOrder: PropTypes.array,
    heading: PropTypes.string,
    headingStyle: PropTypes.oneOf(['sm', 'md']),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    switchDesktopOrder: PropTypes.bool,
    tag: PropTypes.string,
    text: PropTypes.object,
};

export default Information;
