import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';

const ExtraLinks = ({ $style = {}, items = [] }) => {
    const [css] = useStyletron();

    if (!items?.length) {
        return null;
    }

    return (
        <div
            className={css({
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                marginTop: ['24px', null, null, null, '32px'],
                ...$style,
            })}
        >
            {items.map(({ label = '', link = {}, icon }) => (
                <ThemeButton
                    isVolatile
                    key={label}
                    theme="link"
                    $style={{
                        width: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '0 6px',
                        padding: '6px 12px',
                    }}
                    fontKeys="Primary/14_100_-3"
                    as={Link}
                    to={link.url}
                >
                    {icon[0]?.icon[0]?.filename ? (
                        <img
                            className={css({ maxHeight: '16px', maxWidth: '16px' })}
                            src={icon[0]?.icon[0]?.filename}
                        />
                    ) : null}
                    {label}
                </ThemeButton>
            ))}
        </div>
    );
};

ExtraLinks.propTypes = {
    $style: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ExtraLinks;
