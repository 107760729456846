import PropTypes from 'prop-types';
import useSlider from '@activebrands/core-web/libs/slider/useSlider';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import StateButton from 'components/buttons/StateButton';

const ArrowPagination = ({ slider, $style = {} }) => {
    const [css] = useStyletron();
    if (!slider) {
        return null;
    }

    const { isInfinite, numberOfSlides, slidesPerView } = useSlider(slider, [
        'index',
        'isInfinite',
        'numberOfSlides',
        'slidesPerView',
    ]);

    const hide = !isInfinite && numberOfSlides === slidesPerView;

    const style = {
        ...{ display: hide ? 'none' : 'block' },
        ...$style,
    };

    return (
        <>
            <div className={css({ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' })}>
                <StateButton
                    $style={{
                        ...style,
                        transform: 'rotate(180deg)',
                    }}
                    theme="pagination"
                    onClick={() => slider.slideToPrev()}
                />
            </div>
            <div className={css({ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' })}>
                <StateButton $style={{ ...style }} theme="pagination" onClick={() => slider.slideToNext()} />
            </div>
        </>
    );
};

ArrowPagination.propTypes = {
    $style: PropTypes.object,
    slider: PropTypes.object.isRequired,
};

export default ArrowPagination;
