import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import { getBreakpoint } from '@activebrands/core-web/config/breakpoints';
import useNotifications from '@activebrands/core-web/hooks/useNotifications';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import { addBasketItem } from '@activebrands/core-web/state/basket/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import { ecommerceEnabled } from '@activebrands/core-web/utils/constants';
import { logger } from '@activebrands/core-web/utils/logger';
import getIsWithinTeamOrderingPeriod from '@activebrands/core-web/utils/team-store/get-is-within-team-ordering-period';
import ThemeButton from 'components/buttons/ThemeButton';
import LoadingIcon from 'components/icons/LoadingIcon';
import MinusIcon from 'components/icons/MinusIcon';
import PlusIcon from 'components/icons/PlusIcon';
import AddedToBasket from 'components/notifications/AddedToBasket';
import Paragraph from 'components/text/Paragraph';

export const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
});

// @todo: add onclick for adding to wishlist
const AddToBasketCardEnd = ({
    $style,
    addedToBasketAlgoliaData,
    addedToBasketData,
    color,
    isLoading = false,
    isQuickshopOpen = true,
    name,
    priceWithCurrency,
    salePriceWithCurrency,
    setIsLoading = () => null,
    setIsQuickshopOpen,
    uri = '',
    variations = [],
}) => {
    const dispatch = useTypedDispatch();
    const notifications = useNotifications();
    const isOnlyOneVariant = variations.length === 1;

    const orderingEndDateStr = useSelector(state => state?.teamStore?.orderingEndDate);
    const orderingStartDateStr = useSelector(state => state?.teamStore?.orderingStartDate);
    const teamStoreSkus = useSelector(state => state?.teamStore?.variantSkus);
    const shortSku = variations?.[0]?.sku?.substring(0, 10);
    const isTeamStoreProduct = teamStoreSkus?.includes(shortSku);

    if (!ecommerceEnabled) return null;

    let Icon;
    let buttonLabel;

    if (isQuickshopOpen) {
        Icon = MinusIcon;
    } else {
        Icon = PlusIcon;
    }

    if (isLoading) {
        Icon = LoadingIcon;
        buttonLabel = fm('Adding to bag');
    }

    const handleAddToBasket = async variation => {
        setIsLoading(true);
        try {
            const response = await dispatch(addBasketItem(variation.id, variation.trackingMeta));
            if (response.status === 200 || response.status === 201) {
                notifications.push(AddedToBasket, addedToBasketData, 4500);
            }
        } catch (error) {
            logger.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleButtonClick = variation => {
        const isWithinTeamOrderingPeriod = getIsWithinTeamOrderingPeriod(orderingStartDateStr, orderingEndDateStr);

        if (isTeamStoreProduct && !isWithinTeamOrderingPeriod) {
            overlay.open('not-within-team-ordering-period');
            return;
        }
        setIsQuickshopOpen(!isQuickshopOpen);

        const breakpoint = getBreakpoint('mobile.lg');

        if (isOnlyOneVariant) {
            handleAddToBasket(variation);
        } else if (breakpoint.to > window?.innerWidth) {
            overlay.open('quickshop', {
                activePrice: salePriceWithCurrency,
                addedToBasketAlgoliaData,
                addedToBasketData,
                color,
                name,
                originalPrice: priceWithCurrency,
                uri,
                variations,
            });
        }
    };

    return (
        <Wrapper $style={$style}>
            <ThemeButton type="button" onClick={() => handleButtonClick(variations[0])}>
                <Paragraph
                    $style={{ display: ['none', null, null, null, null, 'initial'] }}
                    fontKeys="Primary/14_100_-2_500"
                >
                    {buttonLabel}
                </Paragraph>
                <Icon size="16px" />
            </ThemeButton>
        </Wrapper>
    );
};

AddToBasketCardEnd.propTypes = {
    $style: PropTypes.object,
    addedToBasketAlgoliaData: PropTypes.object,
    addedToBasketData: PropTypes.object,
    color: PropTypes.string,
    isLoading: PropTypes.bool,
    isQuickshopOpen: PropTypes.bool,
    name: PropTypes.string,
    priceWithCurrency: PropTypes.string,
    salePriceWithCurrency: PropTypes.string,
    setIsLoading: PropTypes.func,
    setIsQuickshopOpen: PropTypes.func,
    uri: PropTypes.string,
    variations: PropTypes.array,
};

export default AddToBasketCardEnd;
