import fm from 'format-message';
import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import FeaturedItems from 'components/Header/Navigation/PrimaryMenu/FeaturedItems';
import OverlayHeading from 'components/Header/OverlayHeading';
import StateButton from 'components/buttons/StateButton';
import ThemeButton from 'components/buttons/ThemeButton';
import PrimaryMenuBackdrop from './PrimaryMenuBackdrop';

const Wrapper = styled('div', ({ $isActive, $level }) => ({
    position: 'fixed',
    height: 'calc(100% - var(--height-header) - 8px)',
    left: 0,
    top: 'var(--height-header)',
    visibility: $isActive ? 'visible' : 'hidden',
    width: 'calc(100vw - 8px)',
    transform: 'none',
    zIndex: `calc(var(--zindex-header) + 5 + ${$level})`, // All menus are located on top each other rendered in the dom. This makes the levels stack in correct order in mobile.
    [media.min['desktop.sm']]: {
        top: 0,
        transform: 'translate3d(0, 0, 0)',
        left: `calc(var(--width-overlays) + 4px)`, // 4px added to each menu level to set the gap between them.
        height: 'calc(100vh - 4px - var(--height-header))',
    },
}));

const InnerWrapper = styled('div', ({ $level }) => ({
    height: 'calc(100% - 4px)',
    position: 'relative',
    maxWidth: 'var(--width-overlays)',
    zIndex: `calc(var(--zindex-header) + 7 + ${$level})`,

    [media.min['desktop.sm']]: {
        height: 'calc(100% - 8px)',
    },
}));

const Section = styled('div', ({ $level }) => ({
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: `calc(var(--zindex-header) + 8 + ${$level})`,

    '::after': {
        display: 'block',
        position: 'absolute',
        content: '""',
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: '-1',
    },

    [media.min['desktop.sm']]: {
        height: '100%',
    },
}));

const Nav = styled('section', ({ $level }) => ({
    backgroundColor: 'var(--color-bg-header)',
    color: 'var(--color-text-navigation)',
    height: '100%',
    overflowY: 'auto',
    width: '100%',
    zIndex: `calc(var(--zindex-header) + 9 + ${$level})`,

    [media.min['desktop.sm']]: {
        marginTop: 'unset',
    },
}));

const SubMenuLevel = ({
    featuredItems = [],
    featuredLabel = '',
    isActive = false,
    level,
    menuValue = {},
    parentLabel = '',
    parentLink = {},
    resetNavigation,
    setMenuValue,
    submenus = [],
}) => {
    const [css] = useStyletron();
    const hasFeaturedItems = featuredItems.length > 0;
    const firstLevel = level === 1;

    return (
        <Wrapper $isActive={isActive} $level={level}>
            <PrimaryMenuBackdrop
                $isOpen={isActive}
                $zIndex={`calc(var(--zindex-header) + (6 + ${level}))`}
                onClick={() => resetNavigation()}
            />

            <InnerWrapper $level={level}>
                <Section $isActive={isActive} $level={level}>
                    <Nav $level={level}>
                        <OverlayHeading
                            firstLevel={firstLevel}
                            menuValue={menuValue}
                            resetNavigation={resetNavigation}
                            setMenuValue={setMenuValue}
                            text={parentLabel}
                        />
                        {hasFeaturedItems && (
                            <FeaturedItems
                                $style={{
                                    marginTop: ['none', null, null, null, null, '56px'],
                                }}
                                items={featuredItems}
                                label={featuredLabel}
                            />
                        )}
                        <ul
                            className={css({
                                display: 'flex',
                                flexFlow: 'column',
                                marginBottom: '8px',
                                marginTop: ['0px', null, null, null, null, '56px'],
                            })}
                        >
                            {submenus?.map((levelTwoItem, index) => {
                                const subMenuIsActive = menuValue.current === levelTwoItem._uid;
                                const hasSubmenu = levelTwoItem.submenus?.length > 0;

                                return (
                                    <li key={`${index}_${levelTwoItem._uid}`}>
                                        <StateButton
                                            isVolatile
                                            theme="navigation"
                                            $style={{
                                                width: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                padding: '12px',
                                                transitionDuration: 'var(--duration-fast)',
                                                transitionProperty: 'color',
                                                transitionTimingFunction: null,
                                            }}
                                            active={subMenuIsActive}
                                            fontKeys="Primary/18_100_-05"
                                            as={Link}
                                            hasIcon={hasSubmenu}
                                            to={levelTwoItem?.link?.url}
                                            onClick={e => {
                                                if (hasSubmenu) {
                                                    e.preventDefault();
                                                    setMenuValue({
                                                        current: hasSubmenu ? levelTwoItem._uid : null,
                                                        prev: menuValue.current,
                                                    });
                                                } else {
                                                    resetNavigation();
                                                }
                                            }}
                                        >
                                            {levelTwoItem.label}
                                        </StateButton>
                                        <SubMenuLevel
                                            featuredItems={levelTwoItem.featuredItems}
                                            featuredLabel={levelTwoItem.featuredLabel}
                                            id={levelTwoItem._uid}
                                            isActive={subMenuIsActive}
                                            level={level + 1}
                                            menuValue={menuValue}
                                            parentLabel={levelTwoItem.label}
                                            parentLink={levelTwoItem.link}
                                            resetNavigation={resetNavigation}
                                            setMenuValue={setMenuValue}
                                            subMenuIsActive={subMenuIsActive}
                                            submenus={levelTwoItem.submenus}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                        <div
                            className={css({
                                display: 'flex',
                                marginTop: '8px',
                                marginBottom: '24px',
                                padding: '0 8px',
                            })}
                        >
                            <ThemeButton
                                isVolatile
                                theme="link"
                                $style={{
                                    width: 'auto',
                                    display: 'flex',
                                    padding: '6px 4px',
                                    transitionDuration: 'var(--duration-fast)',
                                    transitionProperty: 'backgroundColor, color, border',
                                    transitionTimingFunction: null,
                                }}
                                fontKeys="Primary/18_100_-05"
                                as={Link}
                                to={parentLink?.url}
                            >
                                {fm('See all')}
                            </ThemeButton>
                        </div>
                    </Nav>
                </Section>
            </InnerWrapper>
        </Wrapper>
    );
};

SubMenuLevel.propTypes = {
    featuredItems: PropTypes.array,
    featuredLabel: PropTypes.string,
    isActive: PropTypes.bool,
    level: PropTypes.number,
    menuValue: PropTypes.object,
    parentLabel: PropTypes.string,
    parentLink: PropTypes.object,
    resetNavigation: PropTypes.func,
    setMenuValue: PropTypes.func,
    submenus: PropTypes.array,
};

export default SubMenuLevel;
