import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const AddToBasketIcon = ({ color = 'currentColor', width = '16px', height = 'auto', $style = {}, ...rest }) => {
    const style = { width, height, ...$style };
    return (
        <SVG $style={style} fill="none" viewBox="0 0 16 16" {...rest}>
            <path stroke={color} d="M7 3.5h7.5v12h-13v-12H4" />
            <path stroke={color} d="M5.5 4V3a2.5 2.5 0 0 1 5 0v1M8 6v7M11.5 9.5h-7" />
        </SVG>
    );
};

AddToBasketIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default AddToBasketIcon;
