import media from '@activebrands/core-web/config/media';
import { styled } from '@activebrands/core-web/libs/styletron';

const NotificationWrapper = styled('div', {
    width: 'calc(100vw - 8px)',
    overflow: 'hidden',
    pointerEvents: 'auto',
    backgroundColor: 'var(--color-bg-notification-wrapper)',
    border: '1px solid var(--color-border-notification-wrapper)',
    margin: '4px',
    padding: '12px',

    [media.min['desktop.sm']]: {
        width: '360px',
    },
});

export default NotificationWrapper;
