import PropTypes from 'prop-types';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';
import getPositionStyles from '@activebrands/core-web/utils/style-functions/get-position-styles';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

// Decide fontKeys based on the blocks context
const fontKeys = {
    oneColumn: {
        heading: 'Primary/32_100_-2',
        paragraph: 'Primary/16_140_-05',
        preamble: ['Primary/18_120_-05', null, null, 'Primary/22_120_-05'],
        subHeading: ['Primary/22_120_-05', null, null, 'Primary/24_120_-1'],
        testimonial: ['Primary/24_120_-1', null, null, 'Primary/32_100_-2'],
        quote: {
            text: ['Primary/24_120_-1', null, null, 'Primary/32_100_-2'],
            author: 'Primary/16_100_-05',
        },
    },
    twoColumns: {
        heading: 'Primary/32_100_-2',
        paragraph: 'Primary/16_140_-05',
        preamble: ['Primary/18_120_-05', null, null, 'Primary/22_120_-05'],
        subHeading: ['Primary/22_120_-05', null, null, 'Primary/24_120_-1'],
        testimonial: ['Primary/24_120_-1', null, null, 'Primary/32_100_-2'],
        quote: {
            text: ['Primary/24_120_-1', null, null, 'Primary/32_100_-2'],
            author: 'Primary/16_100_-05',
        },
    },
    threeColumns: {
        heading: 'Primary/32_100_-2',
        paragraph: 'Primary/16_140_-05',
        preamble: ['Primary/18_120_-05', null, null, 'Primary/22_120_-05'],
        subHeading: ['Primary/22_120_-05', null, null, 'Primary/24_120_-1'],
        testimonial: ['Primary/24_120_-1', null, null, 'Primary/32_100_-2'],
        quote: {
            text: ['Primary/24_120_-1', null, null, 'Primary/32_100_-2'],
            author: 'Primary/16_100_-05',
        },
    },
};

const Text = ({ contentHorizontalAlignment = 'left', id = '', items = [], layout = 'oneColumn' }) => {
    const [css] = useStyletron();

    if (!items?.length) {
        return null;
    }

    const textComponentFontKeys = fontKeys[layout];

    const textComponentStyling = {
        oneColumn: {
            heading: { marginBottom: '16px' },
            paragraph: { marginBottom: '32px' },
            preamble: { marginBottom: ['32px', null, null, '24px'] },
            subHeading: { marginBottom: '16px' },
            testimonial: { textAlign: 'center', padding: '40px 0' },
            quote: { textAlign: 'center', padding: '40px 0' },
        },
        twoColumns: {
            heading: { marginBottom: '16px' },
            paragraph: { marginBottom: '32px' },
            preamble: { marginBottom: ['32px', null, null, '24px'] },
            subHeading: { marginBottom: '16px' },
            testimonial: { textAlign: 'center', padding: '40px 0' },
            quote: { textAlign: 'center', padding: '40px 0' },
        },
        threeColumns: {
            heading: { marginBottom: '16px' },
            paragraph: { marginBottom: '32px' },
            preamble: { marginBottom: ['32px', null, null, '24px'] },
            subHeading: { marginBottom: '16px' },
            testimonial: { textAlign: 'center', padding: '40px 0' },
            quote: { textAlign: 'center', padding: '40px 0' },
        },
    };

    const contentWrapperStyles = {
        oneColumn: {
            width: ['100%', null, null, null, null, '50%'],
        },
        twoColumns: { width: '100%', padding: ['var(--margin-content) 0 0', null, null, '0 24px'] },
        threeColumns: { width: '100%', padding: ['var(--margin-content) 0 0', null, null, '0 24px'] },
    };

    const positionStyles = getPositionStyles({
        contentHorizontalAlignment,
        contentVerticalAlignment: 'top',
    });

    return (
        <div className={css({ display: 'flex', ...positionStyles })} id={id}>
            <div className={css({ ...contentWrapperStyles[layout] })}>
                {items.map(item => {
                    switch (item.component) {
                        case 'heading':
                            return (
                                <Heading
                                    key={item._uid}
                                    $style={textComponentStyling[layout]['heading']}
                                    as="h3"
                                    fontKeys={textComponentFontKeys.heading}
                                >
                                    {item.text}
                                </Heading>
                            );
                        case 'paragraph':
                            return (
                                <div
                                    key={item._uid}
                                    className={css({
                                        ...responsiveTextStyles(textComponentFontKeys.paragraph),
                                        ...textComponentStyling[layout]['paragraph'],
                                    })}
                                >
                                    <RichText data={item.richText} />
                                </div>
                            );
                        case 'preamble':
                            return (
                                <div
                                    key={item._uid}
                                    className={css({
                                        ...responsiveTextStyles(textComponentFontKeys.preamble),
                                        ...textComponentStyling[layout]['preamble'],
                                    })}
                                >
                                    <RichText data={item.preamble} />
                                </div>
                            );
                        case 'subHeading':
                            return (
                                <Heading
                                    key={item._uid}
                                    $style={textComponentStyling[layout]['subHeading']}
                                    as="h4"
                                    fontKeys={textComponentFontKeys.subHeading}
                                >
                                    {item.text}
                                </Heading>
                            );
                        case 'testimonial':
                            return (
                                <div
                                    key={item._uid}
                                    className={css({
                                        ...responsiveTextStyles(textComponentFontKeys.testimonial),
                                        ...textComponentStyling[layout]['testimonial'],
                                    })}
                                >
                                    <RichText data={item.richText} />
                                </div>
                            );
                        case 'quote':
                            return (
                                <div
                                    key={item._uid}
                                    className={css({
                                        ...responsiveTextStyles(textComponentFontKeys.quote.text),
                                        ...textComponentStyling[layout]['quote'],
                                    })}
                                >
                                    <RichText data={item.quote} />
                                    <Paragraph
                                        $style={{ marginTop: '24px' }}
                                        fontKeys={textComponentFontKeys.quote.author}
                                    >
                                        — {item.author}
                                    </Paragraph>
                                </div>
                            );

                        default:
                            return null;
                    }
                })}
            </div>
        </div>
    );
};
Text.propTypes = {
    contentHorizontalAlignment: PropTypes.string,
    id: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    layout: PropTypes.oneOf(Object.keys(fontKeys)),
};

export default Text;
