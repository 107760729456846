import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import useCheckoutUrlQuery from 'queries/useCheckoutUrlQuery';
import Link from '@activebrands/core-web/components/Link';
import useNotifications from '@activebrands/core-web/hooks/useNotifications';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import { ecommerceEnabled } from '@activebrands/core-web/utils/constants';
import ThemeButton from 'components/buttons/ThemeButton';
import CloseIcon from 'components/icons/CloseIcon';
import ProductCardMini from 'components/product/ProductCardMini';
import Heading from 'components/text/Heading';
import NotificationWrapper from 'components/wrappers/NotificationWrapper';
import { useStyletron } from 'styletron-react';

const Header = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 -12px',
    padding: '0 12px 12px',
    borderBottom: '1px solid var(--color-border-added-to-basket)',
});

const Buttons = styled('div', {
    display: 'flex',
    gap: '8px',
    padding: '12px 12px 0',
    margin: '0 -12px',
    borderTop: '1px solid var(--color-border-added-to-basket)',
});

const buttonStyles = {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,

    ...textStyles['Primary/16_100_-05'],
};

const AddedToBasket = ({
    color,
    containImage = false,
    id,
    isTeamStoreProduct,
    media,
    name,
    onMouseEnter,
    onMouseLeave,
    price,
    size,
    uri = '',
    variantName,
}) => {
    const [css] = useStyletron();
    const { checkoutPage } = useCheckoutUrlQuery();
    const notifications = useNotifications();

    const closeNotificationOnViewCart = () => {
        overlay.open('basket');
        notifications.remove(id);
    };

    return (
        <NotificationWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Header>
                <Heading fontKeys="Primary/16_100_-05_500">{fm('Added to bag')}</Heading>
                <CloseIcon
                    $style={{ cursor: 'pointer' }}
                    size="14px"
                    state="close"
                    onClick={() => notifications.remove(id)}
                />
            </Header>
            <div className={css({ padding: '12px 4px' })}>
                <ProductCardMini
                    color={color}
                    containImage={containImage}
                    id={id}
                    isTeamStoreProduct={isTeamStoreProduct}
                    media={media}
                    name={name}
                    size={size}
                    uri={uri}
                    variantName={variantName}
                    price={price}
                    type="order"
                />
            </div>
            <Buttons>
                <ThemeButton
                    $style={buttonStyles}
                    size="sm"
                    theme="outlined"
                    onClick={() => closeNotificationOnViewCart()}
                >
                    {fm('View cart')}
                </ThemeButton>
                {ecommerceEnabled && (
                    <ThemeButton
                        $style={buttonStyles}
                        as={Link}
                        size="sm"
                        theme="inverted"
                        to={checkoutPage}
                        onClick={() => notifications.remove(id)}
                    >
                        {fm('Checkout')}
                    </ThemeButton>
                )}
            </Buttons>
        </NotificationWrapper>
    );
};

AddedToBasket.propTypes = {
    color: PropTypes.string,
    containImage: PropTypes.bool,
    id: PropTypes.string.isRequired,
    isTeamStoreProduct: PropTypes.bool,
    media: PropTypes.object,
    name: PropTypes.string.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    price: PropTypes.object,
    size: PropTypes.string.isRequired,
    uri: PropTypes.string,
    variantName: PropTypes.string,
};

export default AddedToBasket;
