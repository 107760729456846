import PropTypes from 'prop-types';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import CloseIcon from 'components/icons/CloseIcon';
import Heading from 'components/text/Heading';

const Wrapper = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
    borderBottom: '1px solid var(--color-border)',
});

const OverlayHeader = ({ $style = {}, headingText = '', overlayId = '' }) => {
    const [css] = useStyletron();

    if (!headingText && !overlayId) {
        return null;
    }

    return (
        <Wrapper $style={$style}>
            {headingText && <Heading fontKeys="Primary/16_100_-05">{headingText}</Heading>}
            {overlayId && (
                <button
                    className={css({
                        alignItems: 'center',
                        cursor: 'pointer',
                        display: 'flex',
                        height: '40px',
                        justifyContent: 'center',
                        marginRight: '-12px',
                        width: '40px',
                    })}
                    type="button"
                    onClick={() => overlay.close(overlayId)}
                >
                    <CloseIcon size="16px" />
                </button>
            )}
        </Wrapper>
    );
};

OverlayHeader.propTypes = {
    $style: PropTypes.object,
    headingText: PropTypes.string,
    overlayId: PropTypes.string,
};

export default OverlayHeader;
