import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import overlay from '@activebrands/core-web/libs/overlay';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import SubMenuLevel from 'components/Header/Navigation/PrimaryMenu/SubMenuLevel';
import StateButton from 'components/buttons/StateButton';

const PrimaryMenu = ({ items = [], menuValue = {}, resetNavigation = () => null, setMenuValue }) => {
    const [css] = useStyletron();

    if (!items?.length) {
        return null;
    }

    return (
        <ul
            className={css({
                display: 'flex',
                flexDirection: 'column',
                marginBottom: ['24px', null, null, null, '32px'],
            })}
        >
            {items?.map(levelOneItem => {
                const hasSubmenu = levelOneItem.submenus?.length > 0;
                const isActive = menuValue.current === levelOneItem._uid;
                const subMenuIsActive = levelOneItem.submenus.some(subMenu => subMenu._uid === menuValue.current);

                return (
                    <li className={css({ margin: '0px 0' })} key={levelOneItem.label}>
                        <div
                            className={css({
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                            })}
                        >
                            <StateButton
                                theme="navigation"
                                $style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    borderStyle: 'unset',
                                    height: 'auto',
                                    textAlign: 'left',
                                    whiteSpace: 'normal',
                                    padding: '8px 12px',
                                }}
                                $stateTextStyle={{ width: '80%' }}
                                fontKeys="Primary/24_120_-1"
                                active={isActive}
                                as={Link}
                                rel={levelOneItem.follow === 'no_follow' ? 'nofollow' : undefined}
                                to={levelOneItem?.link?.url}
                                hasIcon={hasSubmenu}
                                onClick={e => {
                                    if (hasSubmenu) {
                                        overlay.open('navigation');
                                        e.preventDefault();
                                        setMenuValue({
                                            current: hasSubmenu ? levelOneItem._uid : null,
                                            prev: menuValue.current,
                                        });
                                    } else {
                                        resetNavigation();
                                    }
                                }}
                            >
                                {levelOneItem.label}
                            </StateButton>
                        </div>
                        <SubMenuLevel
                            featuredItems={levelOneItem.featuredItems}
                            featuredLabel={levelOneItem.featuredLabel}
                            isActive={isActive || subMenuIsActive}
                            level={1}
                            menuValue={menuValue}
                            parentLabel={levelOneItem.label}
                            parentLink={levelOneItem.link}
                            resetNavigation={resetNavigation}
                            setMenuValue={setMenuValue}
                            subMenuIsActive={subMenuIsActive}
                            submenus={levelOneItem.submenus}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

PrimaryMenu.propTypes = {
    items: PropTypes.array.isRequired,
    menuValue: PropTypes.exact({
        current: PropTypes.string,
        prev: PropTypes.string,
    }).isRequired,
    resetNavigation: PropTypes.func.isRequired,
    setMenuValue: PropTypes.func.isRequired,
};

export default PrimaryMenu;
