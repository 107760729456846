import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';
import { removeBasketItem, updateBasketLineQuantity } from '@activebrands/core-web/state/basket/actions';
import { useTypedSelector } from '@activebrands/core-web/state/store';
import { ecommerceEnabled } from '@activebrands/core-web/utils/constants';
import typedFm from '@activebrands/core-web/utils/typed-fm';
import StateButton from 'components/buttons/StateButton';
import AmountIncrementer from 'components/inputs/AmountIncrementer';

export const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: 'var(--color-text-product-card-mini)',
});

interface BasketCardEndProps {
    $style?: object;
    line: string;
    quantity: number;
    setIsLoading: (isLoading: boolean) => void;
}

const BasketCardEnd = ({ $style, line, quantity, setIsLoading }: BasketCardEndProps) => {
    const dispatch = useDispatch();
    const items = useTypedSelector(s => s.basket.items);
    const product: any = items.find(i => i.line === line);

    if (!ecommerceEnabled) return null;

    const trackingMeta = {
        brandName: product.brandName,
        ean: product.ean,
        item: product.id,
        productName: product.productName,
        priceAsNumber: product.priceEachAsNumber,
        quantity: quantity,
        sku: product.sku,
        size: product.size,
        parentId: product.parentId,
        silkVariant: product.variationId,
    };

    const updateQuantity = quantity => {
        setIsLoading(true);
        dispatch(updateBasketLineQuantity(line, trackingMeta, quantity, setIsLoading));
    };

    const removeItem = () => {
        setIsLoading(true);
        dispatch(removeBasketItem(line, trackingMeta, quantity));
    };
    return (
        <Wrapper $style={{ alignItems: 'flex-end', ...$style }}>
            <StateButton
                $style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    marginTop: '10px',
                    padding: 0,
                }}
                fontKeys="Misc/14_100_-1_500_UC"
                state="none"
                theme="defaultsecondary"
                onClick={removeItem}
            >
                {typedFm('Remove')}
            </StateButton>
            <AmountIncrementer count={quantity} onChange={quantity => updateQuantity(quantity)} />
        </Wrapper>
    );
};

BasketCardEnd.propTypes = {
    $style: PropTypes.object,
    line: PropTypes.string,
    quantity: PropTypes.number,
};

export default BasketCardEnd;
