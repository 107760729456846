import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import debounce from '@grebban/utils/function/debounce';
import ThemeButton from 'components/buttons/ThemeButton';
import LoadingIcon from 'components/icons/LoadingIcon';
import StateIcon from 'components/icons/StateIcon';

const Text = styled('span', {
    ...textStyles['Primary/14_100_-2_500'],
    minWidth: '16px',
    textAlign: 'center',
});

const AmountIncrementer = ({ count, step = 1, loading = false, onChange, $style }) => {
    const [current, setCurrent] = useState(count);
    const [css] = useStyletron();

    useEffect(() => {
        if (current !== count) {
            setCurrent(count);
        }
    }, [count]);

    const debouncedFunc = useMemo(() => debounce(onChange), [onChange]);

    const buttonStyle = {
        border: '1px solid var(--color-border-buttons-quickshop-disabled)',
        opacity: `${loading ? '0.6' : '1'}`,
        cursor: `${loading ? 'not-allowed' : 'pointer'}`,
        height: ['36px', null, null, null, null, '22px'],
        width: ['36px', null, null, null, null, '22px'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px 0',
        background: 'transparent',
    };

    const decrementAmount = () => {
        debouncedFunc(-step);
        setCurrent(current - step);
    };

    const incrementAmount = () => {
        debouncedFunc(step);
        setCurrent(current + step);
    };

    return (
        <div className={css({ display: 'flex', alignItems: 'center', gap: '12px', ...$style })}>
            <ThemeButton $style={buttonStyle} onClick={loading ? () => {} : decrementAmount}>
                <StateIcon size={['12px', null, null, null, null, '8px']} state="minus" />
            </ThemeButton>

            {loading ? <LoadingIcon /> : <Text>{count}</Text>}

            <ThemeButton $style={buttonStyle} onClick={loading ? () => {} : incrementAmount}>
                <StateIcon size={['12px', null, null, null, null, '8px']} state="plus" />
            </ThemeButton>
        </div>
    );
};

AmountIncrementer.propTypes = {
    $style: PropTypes.object,
    count: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    step: PropTypes.number,
};

export default AmountIncrementer;
