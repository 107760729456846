import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const EditIcon = ({ color = 'currentColor', width = '16px', height = 'auto', $style = {}, ...rest }) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} fill="none" viewBox="0 0 16 16" {...rest}>
            <path
                stroke={color}
                d="m3.001 10-1 4 4-1m-3-3L9.5 3.5M3.001 10l3 3m0 0 6.5-6.5M9.5 3.5l1.793-1.793a1 1 0 0 1 1.414 0l1.586 1.586a1 1 0 0 1 0 1.414L12.501 6.5M9.5 3.5l3 3"
            />
        </SVG>
    );
};

EditIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default EditIcon;
